import React, { useState, useEffect } from "react";
import { CategoryImageicon } from "../../../assets/images";
import "./editprofile.css";
import { Card, CardBody } from "reactstrap";
import { API_URL, baseUrl } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useQuery, useMutation } from "react-query";

const EditProfile = () => {
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    gender: "",
    email: "",
  });

  const getAccountInfo = useQuery({
    queryKey: "accountInfo",
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(
          `${baseUrl}${API_URL.ACCOUNT_LOGIN_DETAILS}`
        );
        const dob = response.data.dob ? response.data.dob.substring(0, 10) : "";
        setUserData({ ...response.data, dob });
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const editAccountInfo = useMutation(
    async (updatedUserData: typeof userData) => {
      const response = await axiosInstance.put(
        `${baseUrl}${API_URL.ACCOUNT_LOGIN_DETAILS}`,
        updatedUserData
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setUserData(data);
        getAccountInfo.refetch();
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    editAccountInfo.mutate(userData);
  };

  return (
    <div className="col-md-12 pt-5">
      <div className="sub-div-headings d-flex pt-3">
        <img src={CategoryImageicon} alt="CategoryImageicon" />
        &nbsp;
        <span>Edit Your Profile</span>&nbsp;
        <span className="line-span">
          <div className="line-div-max-length-ecommerce"></div>
        </span>
      </div>
      <div className="row innerdiv-center">
        <div className="col-md-4 text-start form-group pt-3">
          <label htmlFor="" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="editprofile-input"
            value={userData.email}
            name="email"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-4 text-start form-group pt-3">
          <label htmlFor="" className="form-label">
            Name{" "}
          </label>
          <input
            type="text"
            className="editprofile-input "
            value={userData.firstname}
            name="firstname"
            onChange={handleInputChange}
          />
        </div>
        {/* <div className="col-md-4 text-start form-group pt-3">
          <label htmlFor="" className="form-label">
            Last Name{" "}
          </label>
          <input
            type="text"
            className="editprofile-input"
            value={userData.lastname}
            name="lastname"
            onChange={handleInputChange}
          />
        </div> */}
        <div className="col-md-4 text-start form-group pt-3">
          <label htmlFor="" className="form-label">
            Date Of Birth{" "}
          </label>
          <input
            type="date"
            className="editprofile-input"
            value={userData.dob}
            name="dob"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-12 text-start form-group pt-3">
          <div className="form-label">Gender </div>
          <Card>
            <CardBody>
              <div className="row ">
                <div className="col-md-3">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    checked={userData.gender === "male"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="male" className="px-2">
                    Male{" "}
                  </label>
                </div>
                <div className="col-md-3">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    checked={userData.gender === "female"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="female" className="px-2">
                    Female
                  </label>
                </div>
                <div className="col-md-3">
                  <input
                    type="radio"
                    id="other"
                    name="gender"
                    value="other"
                    checked={userData.gender === "other"}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="other" className="px-2">
                    Other
                  </label>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="pt-3 text-end">
          <button
            className="btn edit-profile-btn px-5"
            onClick={handleSaveClick}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
