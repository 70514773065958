import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import "./styles.css";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { setLocation } from "../../redux/productList/productListSlice";
import { useDispatch } from "react-redux";
import { LatLng } from "leaflet";
import { mapurl } from "../../services/apiConfig";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface LocationPopupProps {
  isOpen: boolean;
  onClose: () => void;
  toggle: () => void;
}

const LocationPopup: React.FC<LocationPopupProps> = ({
  isOpen,
  onClose,
  toggle,
}) => {
  const mapRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [position, setPosition] = useState<LatLng | null>(null);
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );
  const mapCenter: [number, number] = [
    location?.latitude || "8.487492",
    location?.longitude || "76.953228",
  ];

  const MapClickHandler: React.FC<{
    setPosition: React.Dispatch<React.SetStateAction<LatLng | null>>;
  }> = ({ setPosition }) => {
    useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        setPosition(new LatLng(lat, lng));
      },
    });
    return null;
  };

  const MapLogic: React.FC<{
    setPosition: React.Dispatch<React.SetStateAction<LatLng | null>>;
  }> = ({ setPosition }) => {
    const map = useMapEvents({
      locationfound(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
        getAddress(e.latlng.lat, e.latlng.lng);
      },
    });

    const handleMarkerDragEnd = (e: any) => {
      const newPosition = e.target.getLatLng();
      setPosition(newPosition);
    };

    useEffect(() => {
      const handleMapClick = (e: any) => {};

      const handleLocationFound = (e: any) => {
        setPosition(e?.latlng);
        map.flyTo(e?.latlng, map.getZoom());
        getAddress(e?.latlng?.lat, e?.latlng?.lng);
      };
      map.on("click", handleMapClick);
      map.on("locationfound", handleLocationFound);

      return () => {
        map.off("click", handleMapClick);
        map.off("locationfound", handleLocationFound);
      };
    }, [map, setPosition]);

    if (position) {
      return (
        <Marker
          position={position}
          draggable={true}
          eventHandlers={{ dragend: handleMarkerDragEnd }}
        >
          <Popup>
            <span>Deliver Here</span>
          </Popup>
        </Marker>
      );
    } else {
      return null;
    }
  };

  const handleSetLocation = () => {
    if (position) {
      getAddress(position.lat, position.lng);
    }
  };

  const getAddress = async (lat: number, lng: number) => {
    try {
      const { data } = await axios.get(
        `${mapurl}reverse?format=json&lat=${lat}&lon=${lng}`
      );
      const location = {
        latitude: lat,
        longitude: lng,
        formattedAddress: data.display_name,
      };
      dispatch(setLocation(location));
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <div>
          <div className="text-end pb-3">
            <Button onClick={onClose} className="  btn btn-transparent">
              X
            </Button>
          </div>
          <div>
            <MapContainer
              zoom={13}
              style={{ height: "50vh", width: "100%" }}
              center={mapCenter}
              ref={mapRef}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <MapClickHandler setPosition={setPosition} />
              <MapLogic setPosition={setPosition} />
            </MapContainer>
            <div className="d-flex justify-content-end mt-3">
              <Button
                className="btn bg-danger border-0 me-1"
                onClick={handleSetLocation}
                tag={Link}
                to={"/"}
              >
                Set Location
              </Button>
              <Button
                className="btn bg-success border-0"
                onClick={() => mapRef.current.locate()}
                tag={Link}
                to={"/"}
              >
                Locate ME
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LocationPopup;
