import React, { useState } from "react";
import { useQuery } from "react-query";
import "./DeliverySlot.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../services/axios.instance";
import { calenderIcon } from "../../assets/images";
import { API_URL, guestId } from "../../services/apiConfig";
import { useSelector, useDispatch } from "react-redux";
import { updateDeliverySlot } from "../../redux/productList/productListSlice";
import { sendEventToServer } from "../../hooks/sendEventToServer";
interface DeliverySlotProps {
  setSlotValidation: (isValid: boolean) => void;
}

function DeliverySlot({ setSlotValidation }: DeliverySlotProps) {
  const [selectedSlot, setSelectedSlot] = useState<string>("");

  const dispatch = useDispatch();
  const encryptedResponses = useSelector(
    (state: any) => state.checkout.encryptedDataResponse
  );
  const shopId: any = Object.keys(encryptedResponses || {}).find((key) =>
    key.includes("mer")
  );
  const { customerId } = useSelector((state: any) => state.auth);
  const deliveryPlanet = encryptedResponses[shopId]?.planet_id;
  const merchantId = useSelector(
    (state: any) => state.merchant?.cartMerchantId
  );
  const selectedmerchantId = useSelector(
    (state: any) => state.merchant?.selectedMerchantId
  );
  const selectedmeatmerchantId = useSelector(
    (state: any) => state.merchant?.selectedMeatMerchantId
  );

  const selectedorganicmerchantId = useSelector(
    (state: any) => state.merchant?.selectedOrganicMerchantId
  );

  function formatTime(timeString: string) {
    const date = new Date(timeString);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  }

  const { data, isLoading, error } = useQuery<any[], Error>(
    "deliverySlots",
    async () => {
      const merchantId =
        deliveryPlanet === "fishPlanet"
          ? selectedmerchantId
          : deliveryPlanet === "meatPlanet"
          ? selectedmeatmerchantId
          : deliveryPlanet === "fruitVegPlanet"
          ? selectedorganicmerchantId
          : null;
      try {
        if (
          merchantId ||
          selectedmerchantId ||
          selectedmeatmerchantId ||
          selectedorganicmerchantId
        ) {
          const response = await axiosInstance.post(
            API_URL.DELIVERY_SLOT,
            {
              planet_id: deliveryPlanet,
              merchant_id: merchantId,
            },
            {
              headers: {
                version: "v2",
              },
            }
          );
          if (deliveryPlanet === "meatPlanet") {
            sendEventToServer({
              NAME: "CONSUMER_WEB_MEAT_DELIVERYSLOT",
              CUSTOMER_ID: customerId || guestId,
              MODULE: "DELIVERYSLOT",
              PAYLOAD: { payload: response },
              EVENT_CATEGORY: "INFO",
              MERCHANT_ID: selectedmeatmerchantId,
            });
          } else if (deliveryPlanet === "fishPlanet") {
            sendEventToServer({
              NAME: "CONSUMER_WEB_FISH_DELIVERYSLOT",
              CUSTOMER_ID: customerId || guestId,
              MODULE: "DELIVERYSLOT",
              PAYLOAD: { payload: response },
              EVENT_CATEGORY: "INFO",
              MERCHANT_ID: selectedmerchantId,
            });
          } else if (deliveryPlanet === "fruitVegPlanet") {
            sendEventToServer({
              NAME: "CONSUMER_WEB_FRUITVEG_DELIVERYSLOT",
              CUSTOMER_ID: customerId || guestId,
              MODULE: "DELIVERYSLOT",
              PAYLOAD: { payload: response },
              EVENT_CATEGORY: "INFO",
              MERCHANT_ID: selectedorganicmerchantId,
            });
          }
          return response.data;
        }
        return [];
      } catch (error) {
        throw new Error("Failed to fetch delivery slots");
      }
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const currentTime: Date = new Date();

  const todaySlots = data
    ?.filter(
      (slot) =>
        !slot.is_delivery_tomorrow &&
        new Date(slot.order_end_time) >= currentTime
    )

    .map((slot) => {
      return {
        ...slot,
        label: `Today (${formatTime(
          new Date(slot.delivery_start_time).toISOString()
        )} - ${formatTime(new Date(slot.delivery_end_time).toISOString())})`,
      };
    });
  function formatToLocalISOString(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const tomorrowDate = new Date(currentTime);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const tomorrowSlots = data
    ?.map((slot) => {
      if (slot.is_delivery_tomorrow) return null;
      const newDeliveryStartTime = new Date(slot.delivery_start_time);
      newDeliveryStartTime.setDate(newDeliveryStartTime.getDate() + 1);

      const newDeliveryEndTime = new Date(slot.delivery_end_time);
      newDeliveryEndTime.setDate(newDeliveryEndTime.getDate() + 1);

      const newOrderStartTime = new Date(slot.order_start_time);
      newOrderStartTime.setDate(newOrderStartTime.getDate() + 1);

      const newOrderEndTime = new Date(slot.order_end_time);
      newOrderEndTime.setDate(newOrderEndTime.getDate() + 1);

      return {
        ...slot,
        order_start_time: formatToLocalISOString(newOrderStartTime),
        order_end_time: formatToLocalISOString(newOrderEndTime),
        delivery_start_time: formatToLocalISOString(newDeliveryStartTime),
        delivery_end_time: formatToLocalISOString(newDeliveryEndTime),
        label: `Tomorrow (${formatTime(
          newDeliveryStartTime.toISOString()
        )} - ${formatTime(newDeliveryEndTime.toISOString())})`,
      };
    })
    .filter(Boolean);

  const allSlots = [...(todaySlots || []), ...(tomorrowSlots || [])];

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const selectedSlot = allSlots.find((slot) => slot.label === selectedValue);

    if (selectedSlot) {
      setSelectedSlot(selectedValue);
      dispatch(
        updateDeliverySlot({
          startTime: selectedSlot.delivery_start_time,
          endTime: selectedSlot.delivery_end_time,
        })
      );
      setSlotValidation(true);
    } else {
      setSlotValidation(false);
    }
  };

  return (
    <div className="col-12 deliverySlot-container">
      <div className="row">
        <div className="express-card col-md-12">
          <div className="row align-items-center">
            <div className="col-md-12 d-flex align-items-center">
              <img src={calenderIcon} alt="time" className="mr-2" />
              &nbsp;
              <label htmlFor="slotDropdown" className="slot-title">
                Choose delivery slot
              </label>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-9">
              <div className="select-wrapper">
                <select
                  id="slotDropdown"
                  onChange={handleChange}
                  className="select-time-slot"
                >
                  <option value="">Select a time slot</option>
                  {allSlots.map((slot, index) => (
                    <option key={index} value={slot.label}>
                      {slot.label}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon icon={faCaretDown} className="select-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliverySlot;
