import React from "react";
import { Link } from "react-router-dom";
import "./coming-soon.css";
import { ArrowRightCircle } from "react-bootstrap-icons";

const ComingSoon = () => {
  return (
    <div className="col-12 d-flex justify-content-center text-start pt-5">
      <div className="row width-full-page pb-2 relative-div-bg">
        <div className="col-12 text-center">
          <div className="coming-soon-text-head ">Coming Soon !</div>
          <div className="coming-soon-text-content pt-3">Stay Tuned</div>
          <div className="row pt-3">
            <div className="col btn-div-text-end pt-2">
              <Link to="/">
                <button className="btn btn-coming-soon ">
                  <div className="d-flex justify-content-center">
                    <div>Go to Home</div>
                    <div className="icon-hover ">
                      &nbsp;
                      <ArrowRightCircle size={16} />
                    </div>
                  </div>
                </button>
              </Link>
            </div>
            <div className="col btn-div-text-start pt-2">
              <Link to="/FreshFish">
                <button className="btn   btn-coming-soon">
                  <div className="d-flex justify-content-center">
                    <div>Go to Fresh Fish </div>
                    <div className="icon-hover ">
                      &nbsp;
                      <ArrowRightCircle size={16} />
                    </div>
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
