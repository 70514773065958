import React, { useEffect, useState } from "react";
import { fish_thorny } from "../../assets/images";
import "bootstrap/dist/css/bootstrap.min.css";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../services/axios.instance";
import { baseUrl, API_URL, guestId, sessionId } from "../../services/apiConfig";
import productListSlice, {
  selectProductList,
  setLocation,
} from "../../redux/productList/productListSlice";
import Cart from "../fishCard/CartButtons/Cart";
import ProductDetails from "../../pages/ProductDetails";
import { link } from "fs";
import { Link, useNavigate } from "react-router-dom";
import productDataListSlice, {
  setProductList,
} from "../../redux/productDataList/productDataListSlice";
import { setSelectedMerchant } from "../../redux/merchandSlice";
import { setEncryptedResponse } from "../../redux/checkoutSlice/checkoutSlice";
import { FlyingImage } from "../flyingImage/FlyingImage";
import { setFlyingImg } from "../../redux/flyingAnimation/flyingAnimationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFireFlameCurved } from "@fortawesome/free-solid-svg-icons";


interface Product {
  out_of_stock: any;
  vendor_product_inventories: any;
  weight: string;
  product_id: string;
  product_name: string;
  category_name: string;
  badge?: {
    badge_type: string;
    badge_label: string;
  };
  product_details?: {
    images?: string[];
    number_of_pieces?: string;
    unit?: string;
    measure?: number;
  };
  variants?: {
    price?: number;
  }[];
}

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface MeatCardProps {
  selectedProduct: string;
  selectedTypeofCut: string;
  selectedQuantity: string;
  selectedmerchantId: string;
}
interface CartResponse {
  [key: string]: {
    applied_discount_code: string | null;
    shop_information: {
      shop_address: {
        pinCode: string | null;
        businessName: string;
        address: string;
        // Other properties...
      };
      shop_name: string;
      shop_id: string;
      // Other properties...
    };
    note: string | null;
    matrix_user_id: string | null;
    // Other properties...
    products: Array<{
      price: number;
      toppings: any[]; // Assuming this can be any type
      product_id: string;
      base_product_id: string;
      quantity: number;
      // Other properties...
    }>;
  };
}

const OrganicCard: React.FC<MeatCardProps> = ({
  selectedProduct,
  selectedTypeofCut,
  selectedQuantity,
  selectedmerchantId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productList } = useSelector((state: any) => state.productDataList);
  const { customerId } = useSelector((state: any) => state.auth);
  const { longitude, latitude } = useSelector(
    (state: any) => state.location.mapRegion
  );
  const imgSrc = useSelector((state: any) => state?.flyingAnimation?.imgSrc);
  const itemOffsets = useSelector(
    (state: any) => state?.flyingAnimation?.itemOffsets
  );
  const categoryMeatMerchant = useSelector(
    (state: any) => state?.merchant?.selectedMeatMerchantId
  );
  const categoryOrganicMerchant = useSelector(
    (state: any) => state?.merchant?.selectedOrganicMerchantId
  );
  const [flyingImage, setFlyingImage] = useState<{
    imgSrc: string;
    itemOffsets: { top: number; left: number };
  } | null>(null);
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  const [isFiltered, setIsFiltered] = useState(false);
  const [cartKey, setCartKey] = useState(Number);
  const [Cartresponse, setResponse] = useState<CartResponse | null>(null);

  useEffect(() => {
    if (selectedProduct || selectedTypeofCut || selectedQuantity) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [selectedProduct, selectedTypeofCut, selectedQuantity]);

  const [currentLocation, setCurrentLocation] = useState<Coordinates | null>(
    null
  );

  // const { data: fishMerchants, isLoading: merchantLoading } = useQuery({
  //   queryFn: () => {
  //     return new Promise((resolve, reject) => {
  //       if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             const latitude = position.coords.latitude;
  //             const longitude = position.coords.longitude;

  //             setCurrentLocation({ latitude, longitude });
  //             resolve(
  //               axiosInstance.get<any>(
  //                 `${API_URL.FISH_MERCHANT}/${latitude}/${longitude}`
  //               )
  //             );
  //           },
  //           () => {
  //             console.log("Unable to retrieve your location");
  //             reject();
  //           }
  //         );
  //       } else {
  //         console.log("Geolocation not supported");
  //         reject();
  //       }
  //     });
  //   },
  //   enabled: true,
  // });

  // const {
  //   data: cartData,
  //   isLoading: cartLoading,
  //   isError: cartError,
  // } = useQuery({
  //   queryKey: "cartItemEncryption",
  //   queryFn: async () => {
  //     try {
  //       const response = await axiosInstance.get(API_URL.CART_ITEM_ENCRYPTION, {
  //         headers: {
  //           "Content-Type": "text/plain",
  //           version: "v2",
  //         },
  //       });
  //       console.log("encryptCartResponse", response);
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error fetching cart item encryption:", error);
  //       throw new Error("Error fetching cart item encryption");
  //     }
  //   },
  //   onSuccess: (data) => {
  //     const cartItems =
  //       data?.products?.map((item: any) => item.product_id) ?? [];
  //   },
  // });

  const { data: fishMerchantsList, isLoading: merchantIdLoading } = useQuery({
    queryFn: () => {
      if (currentLocation) {
        let location = {
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        };

        // dispatch(setLocation(location));
        return axiosInstance.get<any>(
          `${API_URL.FISH_MERCHANT}/${currentLocation.latitude}/${currentLocation.longitude}`
        );
      }
      return null;
    },
    queryKey: ["getFishMerchant", currentLocation?.latitude],
    enabled: !!currentLocation?.latitude,
  });

  let merchantId: any | null =
    fishMerchantsList?.data?.merchant_info.merchant_id;

  let merObj = {
    id: merchantId,
    name: "",
    address: "",
  };
  // dispatch(setSelectedMerchant(merObj));

  const {
    data: fishProducts,
    error,
    isLoading: productsLoading,
  } = useQuery(
    ["getProducts", location?.latitude, categoryOrganicMerchant],
    async () => {
      try {
        const response = await axiosInstance.post<any>(
          API_URL.PRODUCT_LIST,
          {
            index: "0",
            count: "500",
            country: "ALL",
            business_area: "fruitVegPlanet",
            longitude: location.longitude,
            latitude: location.latitude,
            is_parent_category: true,
            category_id: "ALL",
            customer_id: customerId,
            merchant_id: categoryOrganicMerchant,
          }
          // {
          //   headers: { version: "v8" },
          // }
        );
        dispatch(setProductList(response?.data?.content));
     
        return response.data;
      } catch (error) {
        throw new Error("Error fetching products");
      }
    },
    {
      enabled: !!location?.latitude,
    }
  );

  const { data, isLoading, isError } = useQuery({
    queryKey: "cartItemEncryption",
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(
          guestId && sessionId
            ? API_URL.GUEST_USER_GET_CARTS
            : API_URL.GET_CART_ITEM_ENCRYPTION,
          {
            headers: {
              "Content-Type": "text/plain",
              version: "v3",
            },
          }
        );

        setResponse(response.data);
        dispatch(setEncryptedResponse(response.data));

        if (Cartresponse) {
          const cartKey: any = Object.keys(Cartresponse).find((key) =>
            key.includes("mer")
          );
          setCartKey(cartKey);
        }

        return response.data;
      } catch (error) {
        console.error("Error fetching cart item encryption:", error);
        throw new Error("Error fetching cart item encryption");
      }
    },
  });

  // useEffect(() => {
  //   if (fishProducts) {
  //     dispatch(setProductList(fishProducts));
  //   }
  // }, [fishProducts, dispatch]);

  if (merchantIdLoading || productsLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
  const handleImageClick = (product_id: string) => {
    navigate(`/ProductDetails?Planet=fruitVegPlanet&id=${product_id}`, {
      state: { product_id },
    });
  };

  const filteredFishProducts = fishProducts?.content?.filter(
    (product: Product) => {
      let isMatching = true;

      if (selectedProduct && product.product_name !== selectedProduct) {
        isMatching = false;
      }

      if (selectedTypeofCut && product.category_name !== selectedTypeofCut) {
        isMatching = false;
      }
      const productWeight = parseFloat(product.weight);

      if (selectedQuantity && productWeight !== parseFloat(selectedQuantity)) {
        isMatching = false;
      }

      return isMatching;
    }
  );
  interface CartResponse {
    [key: string]: {
      applied_discount_code: string | null;
      shop_information: {
        shop_address: {
          pinCode: string | null;
          businessName: string;
          address: string;
          // Other properties...
        };
        shop_name: string;
        shop_id: string;
        // Other properties...
      };
      note: string | null;
      matrix_user_id: string | null;
      // Other properties...
      products: Array<{
        price: number;
        toppings: any[]; // Assuming this can be any type
        product_id: string;
        base_product_id: string;
        quantity: number;
        // Other properties...
      }>;
    };
  }

  const allFishProducts = fishProducts?.content;

  const convertWeightToKg = (weight: any) => {
    return Number(weight) >= 1000 ? `${weight / 1000}Kg` : `${weight}g`;
  };

  return (
    <div className="col-md-12 cart-container  pt-3">
      <div className="row">
        {isFiltered
          ? filteredFishProducts
              ?.sort((a: any, b: any) => {
                return a.out_of_stock === b.out_of_stock
                  ? 0
                  : a.out_of_stock
                  ? 1
                  : -1;
              })
              ?.map((product: Product) => (
                <div
                  className="col-lg-3 col-md-4 col-6 mb-3 content-fishcard"
                  key={product.product_id}
                >
                  <div
                    className={`card   ${
                      product.out_of_stock
                        ? "stock-card-out-of-stock"
                        : "stock-card card-hghlght-reverse product-card-cat"
                    }`}
                  >
                    <div className="card-body relative-div-bg">
                      {!product.out_of_stock && product?.badge?.badge_type && (
                        <div className="product-badge-div ">
                          <FontAwesomeIcon icon={faFireFlameCurved} />
                          &nbsp;
                          {product?.badge?.badge_label}
                        </div>
                      )}
                      <div className="card-img-div-top">
                        {" "}
                        <img
                          className={`card-img-top ${
                            product.out_of_stock
                              ? "out-of-stock desaturate"
                              : ""
                          }`}
                          src={
                            product.product_details?.images?.[0] ?? fish_thorny
                          }
                          alt="product image"
                          onClick={() => handleImageClick(product.product_id)}
                        />
                      </div>
                      <div className="card-inner-text-div">
                        <div className="inner-text-heading-four text-one-line ">
                          {product.product_name}
                        </div>
                        <div className="inner-text-content-five-font-12">
                          <span className="">{product.category_name}</span>
                          <span>&nbsp;|&nbsp;</span>
                          <span className="font-color-gray">
                            {product?.product_details?.measure}
                            {product?.product_details?.unit}
                          </span>
                        </div>
                        {/* <div className="inner-text-content-five-font-12 ">
                          <span className="">
                            {convertWeightToKg(product.weight)}net
                          </span>
                          <span className="font-color-gray">&nbsp;|&nbsp;</span>
                          <span className="font-color-gray">
                            {product?.product_details?.number_of_pieces}
                          </span>
                        </div> */}
                        <div className="inner-text-content-five-font-12 ">
                          Price:
                          <span className="inner-text-heading-four">
                            ₹{" "}
                            {product.vendor_product_inventories?.[0]?.price ??
                              0}
                          </span>
                        </div>
                      </div>
                      <div className="inner-text-content-five-font-12">
                        {/* Quantity in Cart:{" "} */}
                        {/* <span className="inner-text-heading-four">
                        {Cartresponse &&
                          Cartresponse[cartKey]?.products.find(
                            (item) => item.product_id === product.product_id
                          )?.quantity}
                      </span> */}
                      </div>
                      {product.out_of_stock ? (
                        <div className="out-of-stock-text">
                          <button className="outofstock">Out of Stock</button>
                        </div>
                      ) : (
                        <>
                          <Cart
                            product={product}
                            quantity={
                              (Cartresponse &&
                                Cartresponse[cartKey]?.products.find(
                                  (item) =>
                                    item.product_id === product.product_id
                                )?.quantity) ??
                              0
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
          : allFishProducts
              ?.slice()
              ?.sort((a: any, b: any) => {
                return a.out_of_stock === b.out_of_stock
                  ? 0
                  : a.out_of_stock
                  ? 1
                  : -1;
              })
              ?.map((product: Product) => {
                // Get quantity for the current product from Cartresponse and cartKey
                const quantity =
                  (Cartresponse &&
                    Cartresponse[cartKey]?.products.find(
                      (p) => p.product_id === product.product_id
                    )?.quantity) ??
                  0;

                return (
                  <div
                    className="col-lg-3 col-md-4 col-6 mb-3 content-fishcard"
                    key={product.product_id}
                  >
                    <div
                      className={`card   ${
                        product.out_of_stock
                          ? "stock-card-out-of-stock"
                          : "stock-card card-hghlght-reverse product-card-cat"
                      }`}
                    >
                      <div className="card-body relative-div-bg">
                        {!product.out_of_stock &&
                          product?.badge?.badge_type && (
                            <div className="product-badge-div ">
                              <FontAwesomeIcon icon={faFireFlameCurved} />
                              &nbsp;
                              {product?.badge?.badge_label}
                            </div>
                          )}
                        <div className="card-img-div-top">
                          <img
                            className={`card-img-top ${
                              product.out_of_stock
                                ? "out-of-stock desaturate"
                                : ""
                            }`}
                            src={
                              product.product_details?.images?.[0] ??
                              fish_thorny
                            }
                            alt="product image"
                            onClick={() => handleImageClick(product.product_id)}
                          />
                        </div>
                        <div className="card-inner-text-div">
                          <div className="inner-text-heading-four text-one-line">
                            {product.product_name}
                          </div>
                          <div className="inner-text-content-five-font-12 ">
                            <span className="">{product.category_name}</span>
                            <span>&nbsp;|&nbsp;</span>
                            {/* <span className="">{product.weight}gm</span> */}
                            <span className="font-color-gray">
                              {product?.product_details?.measure}
                              {product?.product_details?.unit}
                            </span>
                          </div>
                          {/* <div className="inner-text-content-five-font-12 ">
                            <span className="">
                              {convertWeightToKg(product.weight)}net
                            </span>
                            <span className="font-color-gray">
                              &nbsp;|&nbsp;
                            </span>
                            <span className="font-color-gray">
                              {product?.product_details?.measure}
                              {product?.product_details?.unit}
                            </span>
                          </div> */}
                          <div className="inner-text-content-five-font-12">
                            Price :
                            <span className="inner-text-heading-four">
                              {" "}
                              ₹
                              {product.vendor_product_inventories?.[0]?.price ??
                                0}
                            </span>
                          </div>
                        </div>
                        {product.out_of_stock ? (
                          <div className="out-of-stock-text pt-1">
                            <button className=" outofstock">
                              {Cartresponse
                                ? (Cartresponse &&
                                    Cartresponse[cartKey]?.products.find(
                                      (item) =>
                                        item.product_id === product.product_id
                                    )?.quantity) ??
                                  ""
                                : ""}
                              Out of stock
                            </button>
                          </div>
                        ) : (
                          <>
                            {/* Pass product and quantity as props to Cart component */}
                            <Cart
                              product={product}
                              quantity={
                                (Cartresponse &&
                                  Cartresponse[cartKey]?.products.find(
                                    (item) =>
                                      item.product_id === product.product_id
                                  )?.quantity) ??
                                0
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
      </div>
    </div>
  );
};

export default OrganicCard;
