// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.address-modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.address-modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.address-modal-actions .btn {
  width: 45%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile/SavedAddress/SavedAddress.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".address-confirmation-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.address-modal-content {\n  background: white;\n  border-radius: 8px;\n  padding: 20px;\n  width: 90%;\n  max-width: 400px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  text-align: center;\n}\n\n.address-modal-actions {\n  margin-top: 20px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.address-modal-actions .btn {\n  width: 45%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
