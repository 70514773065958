// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .width-in-pixel {
  width: 300px;
  min-width: 300px;
} */

.width-main-div-product {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.product-card-cat:hover {
  box-shadow: 0 1px 2px 0 #eeeeee, 0 1.5px 5px 0 #eeeeee;
}

.restaurant-category-div {
  border: 0.7px solid #eeeeee;
  /* box-shadow: 5px 5px 5px #eeeeee; */
  box-shadow: 0 2px 4px 0 #eeeeee, 0 3px 10px 0 #eeeeee;
  /* width */
  ::-webkit-scrollbar {
    width: 0.1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    /* border-radius: 10px; */
    border-radius: 0;
    box-shadow: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    /* border-radius: 10px; */
    border-radius: 0;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}

.text-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* .custom-button-div {
  height: 100%;
  align-content: end;
  max-height: 80px;
}

.text-div-card {
  max-height: 150px;
  height: 100%;
} */
`, "",{"version":3,"sources":["webpack://./src/components/Food/RestaurantCategory/restaurantcategory.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,sDAAsD;AACxD;;AAEA;EACE,2BAA2B;EAC3B,qCAAqC;EACrC,qDAAqD;EACrD,UAAU;EACV;IACE,YAAY;EACd;;EAEA,UAAU;EACV;IACE,oCAAoC;IACpC,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA,WAAW;EACX;IACE,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA,oBAAoB;EACpB;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;AAC9B;;AAEA;;;;;;;;;GASG","sourcesContent":["/* .width-in-pixel {\n  width: 300px;\n  min-width: 300px;\n} */\n\n.width-main-div-product {\n  width: 100%;\n  overflow-x: scroll;\n  overflow-y: hidden;\n}\n.product-card-cat:hover {\n  box-shadow: 0 1px 2px 0 #eeeeee, 0 1.5px 5px 0 #eeeeee;\n}\n\n.restaurant-category-div {\n  border: 0.7px solid #eeeeee;\n  /* box-shadow: 5px 5px 5px #eeeeee; */\n  box-shadow: 0 2px 4px 0 #eeeeee, 0 3px 10px 0 #eeeeee;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 0.1px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    /* box-shadow: inset 0 0 5px grey; */\n    /* border-radius: 10px; */\n    border-radius: 0;\n    box-shadow: none;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background: #ffffff;\n    /* border-radius: 10px; */\n    border-radius: 0;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    background: #ffffff;\n  }\n}\n\n.text-one-line {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n}\n\n/* .custom-button-div {\n  height: 100%;\n  align-content: end;\n  max-height: 80px;\n}\n\n.text-div-card {\n  max-height: 150px;\n  height: 100%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
