import React, { useState } from "react";
import { CategoryImageicon } from "../../../assets/images";
import { useDispatch } from "react-redux";
import { resetPersistedState } from "../../../redux/authSlice/authSlice";
import { useNavigate } from "react-router-dom";

const LogOut = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsOpen(!isOpen);
    dispatch(resetPersistedState());
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  const handleLogoutNo = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="col-md-12 pt-5">
      <div className="sub-div-headings d-flex pt-3">
        <img src={CategoryImageicon} alt="CategoryImageicon" />
        &nbsp;
        <span>LogOut</span>&nbsp;
        <span className="line-span">
          <div className="line-div-max-length-ecommerce"></div>
        </span>
      </div>
      <div className="row innerdiv-center">
        <div>
          <div className="inner-text-heading-two">
            Are you sure you would like to logout your account ?
          </div>
          <div className="row pt-2">
            <div className="col">
              <button className="btn btn-danger px-3" onClick={handleLogout}>
                Yes
              </button>
              &nbsp;
              <button className="btn btn-success px-3" onClick={handleLogoutNo}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOut;
