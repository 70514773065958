import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductDataListState {
  productList: productData[];
}

interface productData {
  product_id: string;
  merchant_id: string;
  product_category_id: string;
  parent_category_id: string;
  group_id: string;
  product_name: string;
  rating: number;
  merchant_description: string;
  total_reviews: number;
  label: string;
  model_code: string;
  tag_code?: string | null;
  review?: any;
  record_status: number;
  brand?: string | null;
  business_area: string;
  product_details: {
    description: string;
    short_description?: string | null;
    details?: string | null;
    images: string[];
    toppings?: string[] | null;
    product_category_description?: string | null;
    product_type?: string | null;
    last_order_date?: string | null;
    method_of_use?: string | null;
    serving_per_bag?: number;
    serving_size?: number;
    additional_images?: string[];
    health_benefits?: string;
    typical_kerala_dishes?: string;
    taste_and_textures?: string;
    type_of_fish?: string | null;
    unit: string;
    measure?: string | null;
    metric?: string | null;
    number_of_pieces?: string;
  };
  product_specification?: any;
  variants?: any;
  custom_attributes_list?: any; // Custom attributes, can be detailed further
  gst?: number | null; // GST rate, optional
  weight: number; // Weight of the product
  inventories: {
    [vendor_id: string]: {
      product_vendor_id: string; // Unique identifier for the product vendor
      price: number; // Price of the product from the vendor
      original_amount?: number | null; // Original amount before discounts, optional
      reorder_level: number; // Reorder level
      max_quantity: number; // Maximum quantity available
      available_stock: number; // Available stock
      vendor_id: string; // Unique identifier for the vendor
      vendor_name?: string | null; // Vendor name, optional
      promotional_prices?: any; // Promotional prices, can be detailed further
      sales_quantity?: number; // Quantity sold, can be detailed further
      buy_more_payless_option?: any; // Options for buying more, can be detailed further
      merchant_price?: number | null; // Merchant price, optional
    };
  };
  vendors?: any; // Vendor details, can be detailed further
  available_countries: string[]; // List of available countries for shipping
  vendor_product_inventories: {
    product_vendor_id: string; // Unique identifier for the vendor's product
    price: number; // Price of the product from the vendor
    original_amount: number; // Original amount before discounts
    reorder_level: number; // Reorder level
    max_quantity: number; // Maximum quantity available
    available_stock: number; // Available stock
    vendor_id: string; // Unique identifier for the vendor
    vendor_name?: string | null; // Vendor name, optional
    promotional_prices?: any; // Promotional prices, can be detailed further
    sales_quantity?: number; // Quantity sold, can be detailed further
    buy_more_payless_option?: any; // Options for buying more, can be detailed further
    merchant_price?: number | null; // Merchant price, optional
  }[];
  vendor_IDs?: any; // List of vendor IDs, can be detailed further
  is_offer_available: boolean; // Indicates if an offer is available
  sales_quantity: number; // Quantity sold
  product_order_count: number; // Number of times the product has been ordered
  created_at: string; // Timestamp of when the product was created
  status: string; // Current status of the product (e.g., APPROVED, PENDING)
  score: number; // Score or ranking of the product
  merchant_latitude?: number | null; // Merchant latitude, optional
  merchant_longitude?: number | null; // Merchant longitude, optional
  shop_name?: string | null; // Name of the shop, optional
  product_timings: {
    day: string; // Day of the week
    timings: {
      from: string; // Opening time
      to: string; // Closing time
    }[];
  }[]; // Availability timings of the product
  is_available: boolean; // Indicates if the product is currently available
  created_by?: string | null; // User who created the product, optional
  last_updated_by?: string | null; // User who last updated the product, optional
  updated_at?: string | null; // Timestamp of the last update, optional
  search_rank: number;
  out_of_stock: boolean;
  promotion_type?: string | null;
  promotion_code?: string | null;
  discount_description?: string | null; // Description of the discount, optional
  shop_id: string;
  base_product_id: string;
  display_stock: boolean;
  sub_planet?: string | null;
  local_language_name?: string | null;
  local_language_description?: string | null;
  product_nutrition: {
    id?: string | null;
    energy?: number | null;
    total_fat?: number | null;
    saturated_fat?: number | null;
    trans_fat?: number | null;
    sodium?: number | null;
    carbohydrates_total?: number | null;
    sugars?: number | null;
    average_quantity?: number | null;
  }[];
  category_name: string;
  parent_category_name: string;
  whatsapp_product_name: string;
  product_sku: string;
  product_sku_name?: string | null;
  price: number;
  available_stock: number;
  validity?: string | null;
  data_per_day?: number | null;
  UL?: string | null;
  others?: any;
  product_type?: string | null;
  product_rank?: number | null;
  is_favourite: boolean;
  is_currently_available?: boolean | null;
  addon_products?: any;
  is_notified: boolean;
  per_pc_measure?: string | null;
  original_amount: number;
  weight_range?: string | null;
  delivery_slot?: any;
  batch_stock_list?: any;
}


export const productDataListSlice = createSlice({
  name: "productDataList",
  initialState: {
    productList: [],
  } as ProductDataListState,
  reducers: {
    setProductList: (state, action: PayloadAction<productData[]>) => {
      state.productList = action.payload;
    },
  },
});

export const { setProductList } = productDataListSlice.actions;

export const selectProductList = (state: {
  productDataList: ProductDataListState;
}) => state.productDataList.productList;

export default productDataListSlice.reducer;
