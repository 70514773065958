import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { Button } from "reactstrap";
import { LatLng } from "leaflet";
import axios from "axios";
import { mapurl } from "../../services/apiConfig";
import { setAddress } from "../../redux/checkoutSlice/checkoutSlice";
import { useDispatch } from "react-redux";

interface MapComponentProps {
  onSelect(data: any): any;
}

const MapComponent: React.FC<MapComponentProps> = ({ onSelect = () => {} }) => {
  const [position, setPosition] = useState<LatLng | null>(null);
  const dispatch = useDispatch();
  const mapCenter: [number, number] = [8.8720168, 76.8922617];

  // Handle map clicks to set the marker position
  const MapClickHandler: React.FC<{
    setPosition: React.Dispatch<React.SetStateAction<LatLng | null>>;
  }> = ({ setPosition }) => {
    useMapEvents({
      click(event) {
        const { lat, lng } = event.latlng;
        setPosition(new LatLng(lat, lng)); // Update the position state
      },
    });
    return null;
  };

  // Main logic to handle map events, marker dragging, and finding location
  const MapLogic: React.FC<{
    setPosition: React.Dispatch<React.SetStateAction<LatLng | null>>;
  }> = ({ setPosition }) => {
    const map = useMapEvents({
      locationfound(e) {
        setPosition(e.latlng); // Set position when location is found
        map.flyTo(e.latlng, map.getZoom());
        getAddress(e.latlng.lat, e.latlng.lng);
      },
    });

    // Handle marker drag end to update position
    const handleMarkerDragEnd = (e: any) => {
      const newPosition = e.target.getLatLng();
      setPosition(newPosition); // Update the state when marker is dragged
    };

    useEffect(() => {
      map.on("locationfound", (e) => {
        setPosition(e?.latlng);
        map.flyTo(e?.latlng, map.getZoom());
        getAddress(e?.latlng?.lat, e?.latlng?.lng);
      });
    }, [map, setPosition]);

    // Return the marker only if the position is set
    if (position) {
      return (
        <Marker
          position={position}
          draggable={true}
          eventHandlers={{ dragend: handleMarkerDragEnd }}
        >
          <Popup>
            <span>Deliver Here</span>
          </Popup>
        </Marker>
      );
    } else {
      return null;
    }
  };

  // Function to handle clicking "Set Location" and calling the API with pinned location
  const handleSetLocation = () => {
    if (position) {
      getAddress(position.lat, position.lng); // Call API with pinned lat/lng
    } else {
      console.log("No position set.");
    }
  };

  // Function to get the address using lat/lng via API
  function getAddress(lat: number, lng: number) {
    axios(`${mapurl}reverse?format=json&lat=${lat}&lon=${lng}`)
      .then(({ data }) => {
        dispatch(setAddress(data));
        onSelect({ ...data, lat, lng }); // Pass data to parent component via onSelect
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  }

  const mapRef = useRef<any>(null);

  return (
    <>
      <div>
        <MapContainer
          center={mapCenter}
          zoom={13}
          style={{ height: "300px", width: "100%" }}
          ref={mapRef}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <MapClickHandler setPosition={setPosition} />
          <MapLogic setPosition={setPosition} />
        </MapContainer>
        <div className="d-flex justify-content-end mt-3">
          <Button
            className="btn bg-danger border-0 me-1"
            onClick={handleSetLocation}
          >
            Set Location
          </Button>
          <Button
            className="btn bg-success border-0"
            onClick={() => mapRef.current.locate()}
          >
            Locate ME
          </Button>
        </div>
      </div>
    </>
  );
};

export default MapComponent;
