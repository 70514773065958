// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editprofile-input {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  border-radius: 0px;
  padding: 3px;
  padding-left: 5px;
  outline: 1px solid #e9e9e9;
  text-align: start;
  background: #f1f3f0;
  border: 1px solid #e9e9e9;
}

.edit-profile-btn {
  background-color: #17af4b;
  color: white;
}

.edit-profile-btn:hover {
  background-color: white;
  color: #17af4b;
  border: 0.5px solid #17af4b;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile/EditProfile/editprofile.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;EAC1B,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,2BAA2B;AAC7B","sourcesContent":[".editprofile-input {\n  font-family: \"Manrope\", sans-serif;\n  font-size: 16px;\n  font-weight: 500;\n  width: 100%;\n  border-radius: 0px;\n  padding: 3px;\n  padding-left: 5px;\n  outline: 1px solid #e9e9e9;\n  text-align: start;\n  background: #f1f3f0;\n  border: 1px solid #e9e9e9;\n}\n\n.edit-profile-btn {\n  background-color: #17af4b;\n  color: white;\n}\n\n.edit-profile-btn:hover {\n  background-color: white;\n  color: #17af4b;\n  border: 0.5px solid #17af4b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
