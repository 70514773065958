import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FishCard from "../components/fishCard/FishCard";
import AddAddress from "../components/addAddress/addAddress";
import Home from "../pages/Home/Home";
import Checkout from "../pages/Checkout/Checkout";
import HomePage from "../pages/HomePage";
import FreshFish from "../pages/FreshFish";
import MergedHeader from "../components/header/Header";
import ProductDetails from "../pages/ProductDetails";
import Category from "../pages/Category/category";
import MainCategory from "../pages/CatogeryMain";
import ProfilePage from "../pages/Profile";
import {
  MapHelp,
  YourOrders,
  RestaurantProduct,
  Payment,
  RestaurantCategory,
  ProductSearchListing,
} from "../components";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsConditions from "../pages/TermsConditions";
import CookiePolicy from "../pages/CookiePolicy";
import RefundCancellation from "../pages/RefundCancellation";
import OrderDetails from "../components/Profile/OrderDetails";
import FoodHome from "../pages/FoodHome";
import Meat from "../pages/Meat";
import Groceries from "../pages/Groceries";
import ProductviewDetails from "../pages/ProductDetails";
import ComingSoon from "../pages/ComingSoon/ComingSoon";

const RouterComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<HomePage />} />
          <Route path="ComingSoon" element={<ComingSoon />} />
          <Route
            path="/FreshFish"
            element={
              <FreshFish
                onProductChange={(selectedProduct: string) => {}}
                onQuantityChange={(selectedQuantity: string) => {}}
                onTypeofCutChange={(selectedTypeofCutChange: string) => {}}
              />
            }
          />
          {/* <Route path="/addAddress" element={<AddAddress />} /> */}
          <Route path="/checkout" element={<Checkout />} />
          {/* <Route path="/checkout/planet/:planetId" element={<Checkout />} /> */}
          <Route path="/payment" element={<Payment />} />
          <Route path="/ProductDetails" element={<ProductDetails />} />
          <Route path="/FoodDetails" element={<ProductviewDetails />} />
          <Route
            path="/Meat"
            element={
              <Meat
                onProductChange={(selectedProduct: string) => {}}
                onQuantityChange={(selectedQuantity: string) => {}}
                onTypeofCutChange={(selectedTypeofCutChange: string) => {}}
              />
            }
          />
          <Route path="/GroceryDetails" element={<ProductviewDetails />} />
          <Route path="/MapHelp" element={<MapHelp />} />
          <Route path="/OrderDetails" element={<OrderDetails />} />

          <Route
            path="/Category"
            element={
              <MainCategory
                onQuantityChange={(selectedQuantity: string) => {}}
                onTypeofCutChange={(selectedTypeofCutChange: string) => {}}
              />
            }
          />
          <Route path="/Profile" element={<ProfilePage />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="/RefundCancellation" element={<RefundCancellation />} />
          <Route path="/History" element={<YourOrders />} />
          <Route path="/Food" element={<FoodHome />} />
          <Route path="/Grocery" element={<Groceries />} />
          <Route path="/Restaurant/*" element={<RestaurantProduct />} />
          <Route path="/Dish/*" element={<ProductSearchListing />} />
          <Route path="/FoodCategory" element={<RestaurantCategory />} />
          <Route path="/GroceryCategory" element={<RestaurantCategory />} />
          <Route path="/FishCategory" element={<RestaurantCategory />} />
          <Route path="/MeatCategory" element={<RestaurantCategory />} />
        </Route>
        {/* <Route path="/" element={<Home />} />
        <Route path="/addAddress" element={<AddAddress />} />
        <Route path="/checkout" element={<Checkout />} /> */}
      </Routes>
    </Router>
  );
};

export default RouterComponent;
