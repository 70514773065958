import React, { useEffect, useRef } from "react";

import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL, guestId } from "../../services/apiConfig";
import {
  setSelectedMeatMerchantId,
  setSelectedOrganicMerchantId,
} from "../../redux/merchandSlice";
import { meat_merchant } from "../../assets/images";
import { sendEventToServer } from "../../hooks/sendEventToServer";

const OrganicHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );
  const { customerId } = useSelector((state: any) => state.auth);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      const isGuest = !customerId;
      sendEventToServer({
        NAME: isGuest ? "CONSUMER_WEB_GUEST_ORGANIC_MERCHANTS" : "CONSUMER_WEB_ORGANIC_MERCHANTS",
        CUSTOMER_ID: customerId || guestId,
        MODULE: "MERCHANTS",
        PAYLOAD: {},
        EVENT_CATEGORY: "INFO",
        MERCHANT_ID: "",
      });
      isFirstRender.current = false;
    }
  }, [customerId]);
  const { data: response } = useQuery({
    queryFn: () =>
      axiosInstance.post(
        `${API_URL.MEAT_MERCHANTS}`,
        {
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          businessArea: "fruitVegPlanet",
        },
        {
          headers: { version: "v7" },
        }
      ),
    queryKey: ["getMeatMerchants", location?.latitude],
    enabled: !!location?.latitude,
    onSuccess: (data) => {
   console.log("success")
    },
    onError: (error) => {
      console.error("Error fetching meat merchants:", error);
    },
  });
  const handleViewClick = (merchantId: string) => {
    dispatch(setSelectedOrganicMerchantId(merchantId));
    navigate("/Organic", { state: { id: merchantId } });
  };

  const foodMerchants = response?.data || [];

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page-product pb-5 relative-div-bg">
        <div className="inner-text-heading">
          <span className="span-with-green">Choose Your Shop</span>
        </div>
        <div className="row">
          {foodMerchants?.map((merchant: any, index: number) => (
            <div
              key={merchant?.merchant_info?.merchant_id || index}
              className="col-12 p-2 width-in-pixel content-fishcard"
            >
              <Card className="stock-card card-hghlght-reverse product-card-cat">
                <CardBody className="position-relative">
                  <div className="row">
                    <div className="col-lg-3  col-4">
                      <div
                        className="card-img-div-top"
                        onClick={() =>
                          handleViewClick(merchant?.merchant_info?.merchant_id)
                        }
                      >
                        <img
                          className="card-img-top"
                          src={
                            merchant?.merchant_info?.siteLogo || meat_merchant
                          }
                          alt="meat_merchant"
                        />
                      </div>
                    </div>
                    <div className="col-lg-9 col-8">
                      <div className="text-div-card">
                        <div className="card-inner-text-div">
                          <div className="inner-text-heading-four text-one-line">
                            {merchant?.shop_info?.shop_name}
                          </div>
                          <div className="inner-text-heading-four text-one-line font-color-gray">
                            {merchant?.merchant_info?.cuisineName?.join(", ")}
                          </div>
                          <div className="inner-text-content-five-font-12">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex justify-content-start align-items-center inner-text-content-three">
                                <div className="me-3">
                                  {merchant?.shop_info?.shop_address?.city}
                                </div>
                                <div className="me-3">|</div>
                                <div>
                                  <span className="inner-text-heading-four">
                                    {merchant?.kiosk_distance?.toFixed(2)} KM
                                  </span>
                                </div>
                              </div>

                              <div
                                className="inner-text-content-five-font font-color-gray text-end text-nowrap"
                                // style={{ width: "100%" }}
                              >
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="yellow-star"
                                />
                                &nbsp;{" "}
                                {merchant?.merchant_info?.rating ||
                                  "No ratings yet"}
                              </div>
                            </div>
                          </div>
                          <div
                            className="pt-2 custom-button-div text-end"
                            style={{ minHeight: "40px" }}
                          >
                            <button
                              className="add-to-cart-button"
                              style={{
                                position: "absolute",
                                bottom: "0",
                                right: "0",
                              }}
                              onClick={() =>
                                handleViewClick(
                                  merchant?.merchant_info?.merchant_id
                                )
                              }
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrganicHome;
