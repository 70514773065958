import React, { useState } from "react";
import "./profile.css";
import { CategoryImageicon } from "../../assets/images";
import {
  AboutUs,
  ContactUs,
  EditProfile,
  LogOut,
  MapHelp,
  PreviousOrders,
  RemoveAccount,
  SavedAddress,
  YourOrders,
} from "../../components";
import Carousel from "react-multi-carousel";
import Faq from "../../components/Profile/FAQ";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 8,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};
const profileleftdiv = [
  {
    value: "editprofile",
    icons: CategoryImageicon,
    labels: "Edit Your Profile",
  },
  {
    value: "Yourorder",
    icons: CategoryImageicon,
    labels: "Your Orders",
  },
  {
    value: "editaddress",
    icons: CategoryImageicon,
    labels: "Saved Address",
  },

  {
    value: "faq",
    icons: CategoryImageicon,
    labels: "FAQ",
  },
  {
    value: "aboutus",
    icons: CategoryImageicon,
    labels: "About Us",
  },
  {
    value: "contactus",
    icons: CategoryImageicon,
    labels: "Contact Us",
  },
  {
    value: "removeaccount",
    icons: CategoryImageicon,
    labels: "Remove Account",
  },
  {
    value: "logout",
    icons: CategoryImageicon,
    labels: "Log Out",
  },
];

const ProfilePage = () => {
  const [selectProfileDivShow, setProfileDivShow] = useState("editprofile");
  const accessToken = localStorage.getItem("accessToken");
  const handleProfileDivShow = (selecteddiv: string) => {
    setProfileDivShow(selecteddiv);
  };

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-3">
      <div className="row width-full-page pb-5 mt-3  pt-4 div-caroysel-relative-profile">
        <div className="col-12 bg-profile px-0 mt-3">
          <Carousel responsive={responsive} swipeable={true}>
            {profileleftdiv.map((item) => (
              <div
                className="div-btn-profile-page text-center"
                onClick={() => handleProfileDivShow(item.value)}
              >
                {/* <div className="icon-style-profile">
                  <img src={item.icons} alt="" />
                </div> */}
                <div className="px-2">
                  <span
                    className={` profiledivactivehover   ${
                      selectProfileDivShow === item.value
                        ? "profiledivactive"
                        : ""
                    }`}
                  >
                    {item.labels}
                  </span>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="col-12 d-flex justify-content-center profile-minimum-height">
          <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "editprofile" ? "" : "d-none"
            }`}
          >
            <EditProfile />
          </div>
          <div
            className={`row width-full-page  rightsidedivshow ${
              selectProfileDivShow === "Yourorder" ? "pt-3" : "d-none"
            }`}
          >
            {accessToken && <YourOrders />}
          </div>
          <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "editaddress" ? "" : "d-none"
            }`}
          >
            <SavedAddress />
          </div>
          {/* <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "maphelp" ? "" : "d-none"
            }`}
          >
            <MapHelp />
          </div> */}
          <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "faq" ? "" : "d-none"
            }`}
          >
            <Faq />
          </div>
          <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "aboutus" ? "" : "d-none"
            }`}
          >
            <AboutUs />
          </div>
          <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "contactus" ? "" : "d-none"
            }`}
          >
            <ContactUs />
          </div>
          <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "removeaccount" ? "" : "d-none"
            }`}
          >
            <RemoveAccount />
          </div>
          <div
            className={`row width-full-page rightsidedivshow ${
              selectProfileDivShow === "logout" ? "" : "d-none"
            }`}
          >
            <LogOut />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
