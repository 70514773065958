import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import productSlice from "../redux/productList/productListSlice";
import authSlice from "./authSlice/authSlice";
import locationSlice from "./location/locationSlice";

import planetSlice from "./planetSlice";
import merchandSlice, { merchantSlice } from "./merchandSlice";
import productDataListSlice, {
  setProductList,
} from "./productDataList/productDataListSlice";
import checkoutSlice from "./checkoutSlice/checkoutSlice";
import flyingAnimationSlice from "./flyingAnimation/flyingAnimationSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  products: productSlice,
  productDataList: productDataListSlice,

  auth: authSlice,
  // promo: promoSlice,
  location: locationSlice,
  planet: planetSlice,
  merchant: merchandSlice,
  checkout: checkoutSlice,
  flyingAnimation: flyingAnimationSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
