import React, { useState } from "react";
import { useMutation } from "react-query";
import { CategoryImageicon } from "../../../assets/images";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useNavigate } from "react-router-dom";

const RemoveAccount = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const mutation = useMutation(
    "deleteAccount",
    async () => {
      try {
        const response = await axiosInstance.post(
          API_URL.REMOVE_ACCOUNT,
          null,
          {
            headers: {
              version: "v2",
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error("Error removing account");
      }
    },
    {
      onSuccess: () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
      },
    }
  );

  const handleYesClick = (mutation: any) => {
    mutation.mutate();
    setIsOpen(!isOpen);
  };
  const handleNoClick = () => {
    navigate("/");
  };

  return (
    <div className="col-md-12 pt-5">
      <div className="sub-div-headings d-flex pt-3">
        <img src={CategoryImageicon} alt="CategoryImageicon" />
        &nbsp;
        <span>Remove Account</span>&nbsp;
        <span className="line-span">
          <div className="line-div-max-length-ecommerce"></div>
        </span>
      </div>
      <div className="row innerdiv-center">
        <div>
          <div className="inner-text-heading-two">
            Are you sure you would like to remove your account ?
          </div>
          <div className="row pt-2">
            <div className="col">
              <button
                className="btn btn-danger px-3"
                onClick={() => handleYesClick(mutation)}
              >
                Yes
              </button>
              &nbsp;
              <button className="btn btn-success px-3" onClick={handleNoClick}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveAccount;
