import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import MeatCard from "../../components/meatCard/meatCard";
import Filter from "../../components/filterComponent/filterComponent";
// import Banner from "../../components/banner/Banner";
import { ProductCategory } from "../../components";
import { sendEventToServer } from "../../hooks/sendEventToServer";
import { guestId } from "../../services/apiConfig";

interface Props {
  onProductChange: (selectedProduct: string) => void;
  onTypeofCutChange: (selectedTypeofCut: string) => void;
  onQuantityChange: (selectedQuantity: string) => void;
}

const Meat: React.FC<Props> = ({ onProductChange }) => {
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedTypeofCut, setselectedTypeofCut] = useState("");
  const [selectedQuantity, setselectedQuantity] = useState("");
  const [selectedmerchantId, setSelectedMerchantId] = useState("");
  const { customerId } = useSelector((state: any) => state.auth);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      const isGuest = !customerId;
      sendEventToServer({
        NAME: isGuest ? "CONSUMER_WEB_GUEST_MEAT_PRODUCTS" : "CONSUMER_WEB_MEAT_PRODUCTS",
        CUSTOMER_ID: customerId || guestId,
        MODULE: "PRODUCTS",
        PAYLOAD: {},
        EVENT_CATEGORY: "INFO",
        MERCHANT_ID: selectedmerchantId,
      });
      isFirstRender.current = false;
    }
  }, [customerId, selectedmerchantId]);

  const handleProductChange = (selectedProduct: string) => {
    setSelectedProduct(selectedProduct);
  };
  const handleTypeofCutChange = (selectedTypeofCut: string) => {
    setselectedTypeofCut(selectedTypeofCut);
  };
  const handleQuantityChange = (selectedQuantity: string) => {
    setselectedQuantity(selectedQuantity);
  };
  const handleMerchantChange = (merchantId: string) => {
    setSelectedMerchantId(merchantId);
  };
  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-4">
      <div className="row width-full-page pb-5 relative-div-bg">
        <ProductCategory onMerchantChange={handleMerchantChange} />

        <Filter
          onProductChange={handleProductChange}
          onTypeofCutChange={handleTypeofCutChange}
          onQuantityChange={handleQuantityChange}
        />
        <MeatCard
          selectedProduct={selectedProduct}
          selectedTypeofCut={selectedTypeofCut}
          selectedQuantity={selectedQuantity}
          selectedmerchantId={selectedmerchantId}
        />
      </div>
    </div>
  );
};

export default Meat;
