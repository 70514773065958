import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import Cart from "../../components/fishCard/CartButtons/Cart";
import { useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";
import { setProductList } from "../../redux/productDataList/productDataListSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { setLocation } from "../../redux/productList/productListSlice";
import { Bucket } from "../../assets/images";

interface Product {
  out_of_stock: any;
  vendor_product_inventories: any;
  weight: string;
  product_id: string;
  product_name: string;
  parent_category_id: string;
  category_name: string;
  product_details?: {
    images?: string[];
  };
  variants?: {
    price?: number;
  }[];
}
interface Coordinates {
  latitude: number;
  longitude: number;
}

interface FishCardProps {
  selectedTypeofCut: string;
  selectedQuantity: string;
}

const Category: React.FC<FishCardProps> = ({
  selectedTypeofCut,
  selectedQuantity,
}) => {
  const dispatch = useDispatch();

  const [currentLocation, setCurrentLocation] = useState<Coordinates | null>(
    null
  );

  const { data: fishMerchants, isLoading: merchantLoading } = useQuery({
    queryFn: () => {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;

              setCurrentLocation({ latitude, longitude });
              resolve(
                axiosInstance.get<any>(
                  `${API_URL.FISH_MERCHANT}/${latitude}/${longitude}`
                )
              );
            },
            () => {
              console.log("Unable to retrieve your location");
              reject();
            }
          );
        } else {
          console.log("Geolocation not supported");
          reject();
        }
      });
    },
    enabled: true,
  });

  const { data: fishMerchantsList, isLoading: merchantIdLoading } = useQuery({
    queryFn: () => {
      if (currentLocation) {
        let location = {
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        };

        // dispatch(setLocation(location));
        return axiosInstance.get<any>(
          `${API_URL.FISH_MERCHANT}/${currentLocation.latitude}/${currentLocation.longitude}`
        );
      }
      return null;
    },
    queryKey: ["getFishMerchant", currentLocation?.latitude],
    enabled: !!currentLocation?.latitude,
  });

  const navigate = useNavigate();
  const handleImageClick = (product_id: string) => {
    navigate(`/ProductDetails?id=${product_id}`, { state: { product_id } });
  };

  // useEffect(() => {
  //   if (selectedTypeofCut || selectedQuantity) {
  //     setIsFiltered(true);
  //   } else {
  //     setIsFiltered(false);
  //   }
  // }, [selectedTypeofCut, selectedQuantity]);

  // const [isFiltered, setIsFiltered] = useState(false);
  // let merchantId = fishMerchantsList?.data?.merchant_info?.merchant_id;

  // const {
  //   data: fishProducts,
  //   error,
  //   isLoading: productsLoading,
  // } = useQuery("getProducts", async () => {
  //   try {
  //     const response = await axiosInstance.post<any>(
  //       API_URL.PRODUCT_LIST,
  //       {
  //         index: "0",
  //         count: "500",
  //         country: "ALL",
  //         merchant_id: merchantId,
  //         business_area: "fishPlanet",
  //         sub_planet: "all",
  //       },
  //       {
  //         headers: { version: "v8" },
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw new Error("Error fetching products");
  //   }
  // });

  // useEffect(() => {
  //   if (fishProducts) {
  //     dispatch(setProductList(fishProducts));
  //   }
  // }, [fishProducts, dispatch]);

  const [isFiltered, setIsFiltered] = useState(false);

  const location = useLocation();
  const fishId = location.state?.id;
  const { productList } = useSelector((state: any) => state.productDataList);

  const allFishProducts = productList?.content;

  const filteredFishProducts = allFishProducts?.filter((product: Product) => {
    let isMatching = true;

    if (selectedTypeofCut && product.category_name !== selectedTypeofCut) {
      isMatching = false;
    }
    const productWeight = parseFloat(product.weight);

    if (selectedQuantity && productWeight !== parseFloat(selectedQuantity)) {
      isMatching = false;
    }

    return isMatching;
  });

  const matchingProducts = filteredFishProducts?.filter(
    (product: Product) => product.parent_category_id === fishId
  );
  const restOfTheProducts = filteredFishProducts?.filter(
    (product: Product) => product.parent_category_id !== fishId
  );

  return (
    <div className="col-12  text-start pb-4 pt-5">
      <div className="row">
        {matchingProducts && matchingProducts.length > 0 ? (
          matchingProducts?.map((product: Product) => (
            <div className="col-md-3 mb-3" key={product.parent_category_id}>
              <div className="card stock-card card-hghlght">
                <div className="card-body ">
                  {product.product_details?.images && (
                    <div className="card-img-div-top">
                      <img
                        className="card-img-top "
                        src={product.product_details.images[0]}
                        alt="product image"
                        onClick={() => handleImageClick(product.product_id)}
                      />
                    </div>
                  )}

                  <div className="inner-text-heading-four pt-2">
                    {product.product_name}
                  </div>
                  <div className="inner-text-content-five ">
                    <span className="">{product.category_name}</span>
                    <span>&nbsp;|&nbsp;</span>
                    <span className="">{product.weight}gm</span>
                  </div>

                  <>
                    <div className="inner-text-content-five pt-1 pb-1">
                      Price :
                      <span className="inner-text-heading-four">
                        {" "}
                        ₹ {product.vendor_product_inventories[0].price}
                      </span>
                    </div>
                    <Cart product={product} />
                  </>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-12 pb-5">
            <div className="text-center">
              <img
                className=""
                style={{ width: 80 }}
                src={Bucket}
                alt="bucket"
              />
              <div className="" style={{ fontWeight: 800 }}>
                Sorry, this item is not available right now
              </div>
              <div className="error-message">
                {" "}
                We couldn't find what you are looking for. Please try again
                after some time.
              </div>
            </div>
          </div>
        )}
        <div className="col-md-12 py-5">
          <div className="div-caroysel-relative">
            <div className="sub-div-headings d-flex ">
              <span className="line-span">
                <div className=""></div>
              </span>
              <span>More Products</span>
              <span className="line-span">
                <div className=""></div>
              </span>
            </div>
          </div>
        </div>
        {restOfTheProducts?.map((product: Product) => (
          <div className="col-md-3 " key={product.parent_category_id}>
            <div className="card stock-card card-hghlght">
              <div className="card-body ">
                {product.product_details?.images && (
                  <div className="card-img-div-top">
                    <img
                      className="card-img-top "
                      src={product.product_details.images[0]}
                      alt="product image"
                      onClick={() => handleImageClick(product.product_id)}
                    />
                  </div>
                )}

                <div className="inner-text-heading-four pt-2">
                  {product.product_name}
                </div>
                <div className="inner-text-content-five ">
                  <span className="">{product.category_name}</span>
                  <span>&nbsp;|&nbsp;</span>
                  <span className="">{product.weight}gm</span>
                </div>

                <>
                  <div className="inner-text-content-five pt-1 pb-1">
                    Price :
                    <span className="inner-text-heading-four">
                      {" "}
                      ₹{product.vendor_product_inventories[0].price}
                    </span>
                  </div>
                  <Cart product={product} />
                </>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
