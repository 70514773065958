interface Coordinate {
    distance: number;
    latitude: number;
    longitude: number;
}
interface AddressObject {
    address1?: string;
    street?: string;
    city?: string;
    district?: string;
    state?: string;
    pinCode?: string;
    landMark?: string;
    country?: string;
}

export const calculateDistance = (coord1: Coordinate, coord2: Coordinate): number => {
    const lat1 = coord1.latitude;
    const lon1 = coord1.longitude;
    const lat2 = coord2.latitude;
    const lon2 = coord2.longitude;

    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
};

export const calculateNearest = (currentLocation: Coordinate, addressList: Coordinate[]): Coordinate | null => {
    const closestAddress = addressList.reduce(
        (closest: Coordinate, address: Coordinate) => {
            const { latitude, longitude } = address;
            const distance = calculateDistance(currentLocation, {
                latitude, longitude,
                distance: 0
            });
            if (distance < closest.distance) {
                return { ...address, distance };
            }
            return closest;
        },
        { distance: Number.MAX_VALUE, latitude: 0, longitude: 0 } as Coordinate // Explicitly type the initial value
    );

    // Check if no address found
    if (closestAddress.distance === Number.MAX_VALUE) {
        return null;
    }

    return closestAddress;
};



export const apiAddressFormat = (addressObject: AddressObject): string => {
    if (!addressObject) {
        return "";
    }

    const {
        address1 = "",
        street = "",
        city = "",
        district = "",
        state = "",
        pinCode = "",
        landMark = "",
        country = ""
    } = addressObject;

    // Build an array to hold the formatted parts of the address
    const addressParts: string[] = [];

    // Add parts to the address if they are not empty
    if (address1) addressParts.push(address1);
    if (street) addressParts.push(street);
    if (district) addressParts.push(district);
    if (city) addressParts.push(city);
    if (state) addressParts.push(state);
    if (pinCode) addressParts.push(pinCode);
    if (country) addressParts.push(country);

    // Join the address parts with commas and return the result
    return addressParts.join(", ").trim();
};

