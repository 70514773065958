import React from "react";
import Carousel from "react-multi-carousel";
import {
  CategoryImageicon,
  foodIcon,
  rectangleGreen,
  rectangles,
  fish_demo,
  meat_demo,
} from "../../../assets/images";
import { Card, CardBody } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedMeatMerchantId,
  setSelectedMerchant,
  setSelectedMerchantId,
} from "../../../redux/merchandSlice";
interface ProductCategoryProps {
  onMerchantChange: (selectedmerchantId: string) => void;
}

const ProductCategory: React.FC<ProductCategoryProps> = ({
  onMerchantChange,
}) => {
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );
  const dispatch = useDispatch();
  const locations = useLocation();
  const pathSegments = locations.pathname.split("/");
  const routeName = pathSegments[1] || "Unknown";

  const planet = routeName
    ? routeName === "Food"
      ? "foodPlanet"
      : routeName === "Grocery"
      ? "groceryPlanet"
      : routeName === "FreshFish"
      ? "fishPlanet"
      : routeName === "Meat"
      ? "meatPlanet"
      : null
    : null;

  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 932, min: 568 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const handleImageClick = (
    category_id: string,
    category_name: string,
    parent_category: string | undefined,
    subcategories: any[],
    merchantId: string
  ) => {
    const routeName =
      planet === "foodPlanet"
        ? "FoodCategory"
        : planet === "groceryPlanet"
        ? "GroceryCategory"
        : planet === "fishPlanet"
        ? "FishCategory"
        : planet === "meatPlanet"
        ? "MeatCategory"
        : null;
    navigate(`/${routeName}`, {
      state: {
        id: category_id,
        name: category_name,
        parent_category: parent_category,
        subcategories: subcategories,
        merchantId: merchantId,
      },
    });
  };

  const { data: response, refetch } = useQuery({
    queryFn: () => {
      if (planet === "fishPlanet") {
        return axiosInstance.post(API_URL.FRESH_FISH_HOME, {
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          businessArea: planet,
          isParentCategory: true,
          parentCategoryId: "",
        });
      } else if (planet === "meatPlanet") {
        return axiosInstance.post(API_URL.FRESH_FISH_HOME, {
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          businessArea: planet,
          isParentCategory: true,
          parentCategoryId: "",
        });
      } else {
        return axiosInstance.post(
          planet === "foodPlanet"
            ? API_URL.FOOD_CATEGORIES
            : API_URL.GROCERRY_CATOGORY_LIST,
          {
            latitude: location?.latitude,
            longitude: location?.longitude,
            res: 6,
            businessArea: planet,
            ...(planet === "groceryPlanet" && {
              isParentCategory: true,
              parentCategoryId: "",
            }),
          },
          {
            ...(planet === "foodPlanet" && {
              headers: { version: "v2" },
            }),
          }
        );
      }
    },
    queryKey: [
      planet === "foodPlanet" ? "getCategories" : "getGroceryCategories",
      location.latitude,
    ],
    enabled: !!location.latitude,
    onSuccess: (data) => {
      const productCategory = data?.data || [];
      if (productCategory.length > 0 && productCategory[0]?.merchant_id) {
        onMerchantChange(productCategory[0].merchant_id);
      }
      if (planet === "fishPlanet") {
        dispatch(setSelectedMerchantId(productCategory[0]?.merchant_id));
      } else if (planet === "meatPlanet") {
        dispatch(setSelectedMeatMerchantId(productCategory[0]?.merchant_id));
      }
    },
  });

  const productCategory = response?.data || [];

  return (
    <div className="col-md-12 pt-4">
      <div className="div-caroysel-relative">
        <div className="sub-div-headings d-flex">
          <img src={rectangles} alt="rectangle" />
          &nbsp;
          <span>Categories</span>&nbsp;
          <span className="line-span">
            <div className="line-div-max-length-ecommerce"></div>
          </span>
        </div>
        <div className="col-md-12">
          <Carousel responsive={responsive} swipeable={true}>
            {Array.isArray(productCategory) && productCategory.length > 0 ? (
              productCategory.map((food: any) => (
                <div className="p-2 content-fishcard" key={food.category_id}>
                  <Card className="card-freshfish-carousel card-hghlght">
                    <CardBody>
                      <div className="text-center">
                        <img
                          src={
                            planet === "fishPlanet"
                              ? food?.stock_photo || fish_demo
                              : planet === "meatPlanet"
                              ? food?.stock_photo || meat_demo
                              : foodIcon
                          }
                          alt="planet image"
                          className="img-freshfish-carousel"
                          onClick={() =>
                            handleImageClick(
                              food?.category_id,
                              food?.name,
                              food?.parent_category,
                              food?.subcategories,
                              food?.merchant_id
                            )
                          }
                        />
                      </div>
                      <div className="inner-text-content-five text-center pt-1">
                        {food?.name}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))
            ) : (
              <div className="inner-text-heading-three">
                No categories available
              </div>
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
