import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  phoneNumber: string;
  emailAddress: string;
  name: string;
  customerId: string;
  isLoggedIn: boolean;
  isRegistered: boolean;
  vendorId: string;
  guest: string;
  localPhoneNumber: string;
}

const initialState: AuthState = {
  isLoggedIn: false,
  isRegistered: false,
  phoneNumber: "",
  emailAddress: "",
  name: "",
  customerId: "",
  vendorId: "",
  guest: "",
  localPhoneNumber: ""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAppData: (state, action) => {
      const payload = action.payload;

      state.isLoggedIn = true;
      state.isRegistered = true;
      state.customerId = payload.customer_id;
      state.vendorId = payload.vendor;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setGuestUser: (state, action: PayloadAction<string>) => {
      state.guest = action.payload;
    },
    setGuestPhoneNumber: (state, action: PayloadAction<string>) => {
      state.localPhoneNumber = action.payload;
    },
  },
});

export const { setPhoneNumber, setEmailAddress, setName, setAppData, setGuestUser, setGuestPhoneNumber } =
  authSlice.actions;
export default authSlice.reducer;

export const resetPersistedState = () => {
  return {
    type: "RESET_PERSISTED_STATE",
  };
};
