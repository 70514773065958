import { createSlice } from '@reduxjs/toolkit';

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
 
    encryptedDataResponse: null,
    promocode:null,
    index:0,
    selectedAddress: null,
   },
  reducers: {
    // Other reducers
    setEncryptedResponse(state, action) {
      state.encryptedDataResponse = action.payload;
    },
    setSelectedPromocode(state, action) {
      const{PromoCode,index} = action.payload;
      state.promocode=PromoCode
      state.index=index
    },
    setAddress(state, action) {
      state.selectedAddress = action.payload;  
    },
    removePromocode(state) {


      state.promocode = null;  
      state.index = 0;  
    },
  }, 
});

export const { setEncryptedResponse ,setSelectedPromocode,setAddress,removePromocode} = checkoutSlice.actions;
export default checkoutSlice.reducer;
