import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { Erroricon, banner } from "../../assets/images";

interface ErrorModalProps {
  isOpenModal: boolean;
  onCloseModal: () => void;
  toggleModal: () => void;
  message: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpenModal,
  onCloseModal,
  toggleModal,
  message,
}) => {
  return (
    <Modal isOpen={isOpenModal} toggle={onCloseModal} backdrop="static">
      <ModalBody>
        <div>
          <div className="text-end">
            <Button onClick={onCloseModal} className="btn btn-danger">
              X
            </Button>
          </div>
          <div className="p-2 text-center">
            <img src={Erroricon} alt="Error icon" className="img-fluid img-modal-icons" />
            <div className="sub-div-modal-headings pt-3">{message}</div>
            <div className="pt-3">
              <button
                className="btn green-button-style px-3"
                onClick={onCloseModal}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ErrorModal;
