import React, { useEffect, useState } from "react";
import "./productdetails.css";
// import { Carousel } from "react-responsive-carousel";
import { useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL, guestId, sessionId } from "../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import {
  cart_trolly,
  fish_demo,
  fish_thorny,
  king_fish,
  share,
  tuna_fish,
} from "../../assets/images";
import Add from "../../components/fishCard/CartButtons/Add";
import Cart from "../../components/fishCard/CartButtons/Cart";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { log } from "console";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import Carousel from "react-multi-carousel";
import { FlyingImage } from "../../components/flyingImage/FlyingImage";
import { setFlyingImg } from "../../redux/flyingAnimation/flyingAnimationSlice";
import { useDispatch } from "react-redux";
import { setEncryptedResponse } from "../../redux/checkoutSlice/checkoutSlice";

interface CartResponse {
  [key: string]: {
    applied_discount_code: string | null;
    shop_information: {
      shop_address: {
        pinCode: string | null;
        businessName: string;
        address: string;
        // Other properties...
      };
      shop_name: string;
      shop_id: string;
      // Other properties...
    };
    note: string | null;
    matrix_user_id: string | null;
    // Other properties...
    products: Array<{
      price: number;
      toppings: any[]; // Assuming this can be any type
      product_id: string;
      base_product_id: string;
      quantity: number;
      // Other properties...
    }>;
  };
}

interface Product {
  out_of_stock: boolean;
  product_id: string;
  parent_category_name: string;
  product_details: {
    description: string;
    images: string[] | null;
  };
  inventories: {
    [key: string]: {
      price: number;
      available_stock: number;
    };
  };
  category_name: string;
  weight: number;
  vendor_product_inventories: {
    price: number;
  }[];
}

interface TabContent {
  energy?: string;
  total_fat?: string;
  saturated_fat?: string;
  sodium?: string;
  carbohydrates_total?: string;
  sugars?: string;
  trans_fat?: string;
}

const ProductviewDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  // const productId = location.state.product_id;
  const { customerId } = useSelector((state: any) => state.auth);
  const planetId = location.state?.planetId;
  const [Cartresponse, setResponse] = useState<CartResponse | null>(null);
  const [cartKey, setCartKey] = useState(Number);

  const dispatch = useDispatch();

  const [isFavourite, setIsFavourite] = useState(false);
  const [tabContent, setTabContent] = useState<TabContent>({});
  const [mainImage, setMainImage] = useState<string>("");
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [planetIdProduct, setPlanetIdProduct] = useState("");

  const location_customer = useSelector(
    (state: { products: { location: any } }) => state?.products?.location
  );
  const categoryMerchant = useSelector(
    (state: any) => state?.merchant?.selectedMerchantId
  );

  const imgSrc = useSelector((state: any) => state?.flyingAnimation?.imgSrc);
  const itemOffsets = useSelector(
    (state: any) => state?.flyingAnimation?.itemOffsets
  );

  const {
    data: productDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(["product", productId], async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL.SINGLE_PRODUCT_LIST}/${productId}?customer_id=${customerId}`,
        {
          headers: {
            version: "v2",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Error fetching product details");
    }
  });
  const { data } = useQuery({
    queryKey: "cartItemEncryption",
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(
          guestId && sessionId
            ? API_URL.GUEST_USER_GET_CARTS
            : API_URL.GET_CART_ITEM_ENCRYPTION,
          {
            headers: {
              "Content-Type": "text/plain",
              version: "v3",
            },
          }
        );
        setResponse(response.data);
        dispatch(setEncryptedResponse(response.data));

        if (Cartresponse) {
          const cartKey: any = Object.keys(Cartresponse).find((key) =>
            key.includes("mer")
          );
          setCartKey(cartKey);
        }

        return response.data;
      } catch (error) {
        console.error("Error fetching cart item encryption:", error);
        throw new Error("Error fetching cart item encryption");
      }
    },
  });

  useEffect(() => {
    if (productDetails) {
      setMainImage(productDetails?.product_details?.images?.[0] || fish_demo);
      setAdditionalImages(
        productDetails?.product_details?.additional_images || []
      );
      setPlanetIdProduct(productDetails?.business_area);
      setIsFavourite(productDetails?.is_favourite ?? false);
    }
    dispatch(setFlyingImg({ imgSrc: null, itemOffsets: null }));
  }, [productDetails]);

  const handleThumbnailClick = (image: string) => {
    setMainImage(image);
  };

  const handleTabSelect = (key: string | null) => {
    if (
      key === "Nutrition" ||
      key === "Health benefits" ||
      key === "Texture and taste"
    ) {
      setTabContent(productDetails?.product_nutrition?.[0]);
    }
  };
  const { data: recomendedProduct } = useQuery<Product[]>(
    ["recomendedproducts", customerId, planetIdProduct],
    async () => {
      try {
        const response = await axiosInstance.post<Product[]>(
          `${API_URL.TOP_PRODUCTS}`,
          {
            customer_latitude: location_customer.latitude,
            customer_longitude: location_customer.longitude,
            customer_id: customerId,
            planet_id: planetIdProduct,
            merchant_id: categoryMerchant,
          }
        );
        return response.data;
      } catch (error: any) {
        console.error("Error fetching recommended products:", {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
        });
        throw new Error(
          error.response?.data?.message || "Error fetching recommended products"
        );
      }
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  const addToFavourite = async () => {
    try {
      const response = await axiosInstance.post(
        `product-consumer/products/favourite/insert`,
        {
          product_id: productId,
          planet_id: planetIdProduct,
          customer_id: customerId,
        }
      );

      if (response) {
        setIsFavourite(true);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeFromFavourite = async () => {
    try {
      const resp = await axiosInstance.delete(
        "product-consumer/products/favourite/remove",
        {
          data: {
            product_id: productId,
            planet_id: planetIdProduct,
            customer_id: customerId,
          },
        }
      );

      setIsFavourite(false);
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };
  const handleAddToFavourites = () => {
    if (isFavourite) {
      removeFromFavourite();
    } else {
      addToFavourite();
    }
  };

  const handleShare = () => {
    const nav = navigator as Navigator & {
      share?: (data: {
        title: string;
        text: string;
        url: string;
      }) => Promise<void>;
    };

    if (nav.share) {
      nav
        .share({
          title: productDetails?.product_name || "Check this out!",
          text:
            productDetails?.product_details?.description ||
            "Explore this product.",
          url: window.location.href,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error: unknown) => {
          if (error instanceof Error) {
            console.error("Error sharing:", error.message);
          } else {
            console.error("Unknown error occurred:", error);
          }
        });
    } else {
      console.error("Web Share API not supported in this browser");
      alert("Sharing not supported on this device.");
    }
  };

  const handleImageClick = (product_id: string, business_area: string) => {
    navigate(`/ProductDetails?Planet=${business_area}&id=${product_id}`, {
      state: { product_id },
    });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 400 },
      items: 3,
    },
    mobile1: {
      breakpoint: { max: 400, min: 0 },
      items: 2,
    },
  };

  const allImages = [
    productDetails?.product_details?.images?.[0] || fish_demo,
    ...(productDetails?.product_details?.additional_images || []),
  ];

  const convertWeightToKg = (weight: any) => {
    return Number(weight) >= 1000 ? `${weight / 1000}Kg` : `${weight}g`;
  };

  const accessToken = localStorage.getItem("accessToken");

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page pb-5">
        <div className="col-12 ">
          <div className="row ">
            <div className="col-md-4 ">
              <div className="image-container">
                <img
                  src={mainImage}
                  alt="product"
                  className={`${
                    productDetails?.out_of_stock ? "desaturate" : ""
                  }`}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "250px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div>
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  arrows={false}
                >
                  {allImages.map((image, index) => (
                    <div
                      key={index}
                      onClick={() => handleThumbnailClick(image)}
                      className="p-1"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={image}
                        alt="product"
                        className={` image-thumb-product ${
                          productDetails?.out_of_stock ? "desaturate" : ""
                        }`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>

              {/* <div className="col-md-5 additional-image-container">
                <div
                  onClick={() =>
                    handleThumbnailClick(
                      productDetails?.product_details?.images?.[0]
                    )
                  }
                  className="p-1"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={productDetails?.product_details?.images?.[0]}
                    alt="product"
                    className={` image-thumb-product ${
                      productDetails?.out_of_stock ? "desaturate" : ""
                    }`}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "4px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                {additionalImages.map((image, index) => (
                  <div
                    key={index}
                    onClick={() => handleThumbnailClick(image)}
                    className="p-1"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={image}
                      alt="product"
                      className={` image-thumb-product ${
                        productDetails?.out_of_stock ? "desaturate" : ""
                      }`}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "4px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                ))}
              </div> */}
            </div>
            <div className="col-md-7 right-product-div">
              <div className="fish-details-text">
                {productDetails?.product_name}
              </div>
              <div className="fish-details-text-cat pt-1">
                {productDetails?.category_name}
              </div>
              <div className="fish-details-text-gm pt-1">
                {productDetails?.weight} g
              </div>
              <div className="fish-details-text pt-1">
                Price :
                <span className="weight-inner-text-heading-four">
                  {" "}
                  ₹ {productDetails?.price}
                </span>
              </div>

              <div className="col-4 pt-2">
                {productDetails?.out_of_stock ? (
                  <div className="out-of-stock">Out of Stock</div>
                ) : (
                  <Cart
                    product={productDetails}
                    planet={planetId}
                    quantity={
                      (Cartresponse &&
                        Cartresponse[cartKey]?.products.find(
                          (item) => item.product_id === productId
                        )?.quantity) ??
                      0
                    }
                  />
                )}
              </div>
              <div className="fish-desc-text pt-32 mt-4">Description</div>
              <div className="desc-text pt-1">
                {productDetails?.product_details?.description}
              </div>

              <div className="mt-3 d-flex align-items-center">
                {accessToken && (
                  <div
                    className="d-flex align-items-center"
                    onClick={handleAddToFavourites}
                    style={{ cursor: "pointer", marginRight: "15px" }}
                  >
                    <FontAwesomeIcon
                      icon={isFavourite ? faHeartSolid : faHeartRegular}
                      style={{
                        fontSize: "18px",
                        borderColor: "1px solid #333132",
                        color: isFavourite ? "green" : "#333132",
                        transition: "color 0.3s",
                      }}
                      className={isFavourite ? "icon-hover" : ""}
                    />
                    <span className="ms-2">Add to Favourites</span>
                  </div>
                )}

                <div
                  className="d-flex align-items-center"
                  onClick={handleShare}
                  style={{ cursor: "pointer" }}
                >
                  {/* <FontAwesomeIcon
                    icon={faShareAlt}
                    className="icon-share"
                    style={{ fontSize: "24px" }}
                  /> */}
                  <img className="img-fluid" src={share} alt="share" />
                  <span className="ms-2">Share this Product</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5 product-details-container">
            {productDetails?.product_nutrition?.[0] ||
            productDetails?.health_benefits ||
            productDetails?.taste_and_textures ? (
              <Tabs
                defaultActiveKey="Nutrition"
                id="uncontrolled-tab-example"
                className="mb-3 ov"
                onSelect={handleTabSelect}
              >
                {productDetails?.product_nutrition[0] && (
                  <Tab
                    eventKey="Nutrition"
                    title="Nutrition ( per 100g serving )"
                  >
                    <div className="tab-text">
                      &#8226; Energy:{" "}
                      {productDetails?.product_nutrition?.[0].energy}
                    </div>
                    <div className="tab-text">
                      &#8226; Total Fat:{" "}
                      {productDetails?.product_nutrition[0]?.total_fat}
                    </div>
                    <div className="tab-text">
                      &#8226; Saturated Fat:{" "}
                      {productDetails?.product_nutrition[0]?.saturated_fat}
                    </div>
                    <div className="tab-text">
                      &#8226; Sodium:{" "}
                      {productDetails?.product_nutrition[0]?.sodium}
                    </div>
                    <div className="tab-text">
                      &#8226; Carbohydrates:{" "}
                      {
                        productDetails?.product_nutrition[0]
                          ?.carbohydrates_total
                      }
                    </div>
                    <div className="tab-text">
                      &#8226; Sugars:{" "}
                      {productDetails?.product_nutrition[0]?.sugars}
                    </div>
                    <div className="tab-text">
                      &#8226; Trans Fat:{" "}
                      {productDetails?.product_nutrition[0]?.trans_fat}
                    </div>
                  </Tab>
                )}

                {productDetails?.product_details?.health_benefits && (
                  <Tab eventKey="Health benefits" title="Health benefits">
                    <div className="tab-text">
                      {productDetails?.product_details?.health_benefits}
                    </div>
                  </Tab>
                )}

                {productDetails?.product_details?.taste_and_textures && (
                  <Tab eventKey="Texture and taste" title="Texture and taste">
                    <div className="tab-text">
                      {productDetails?.product_details?.taste_and_textures}
                    </div>
                  </Tab>
                )}
              </Tabs>
            ) : null}
          </div>
          {imgSrc && itemOffsets && (
            <FlyingImage
              imgSrc={mainImage}
              itemOffsets={itemOffsets}
              onAnimationEnd={() =>
                dispatch(setFlyingImg({ imgSrc: null, itemOffsets: null }))
              }
            />
          )}
          {/* <div className="benifit-head-text">Health benefits</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.health_benefits}
            </div>
            <div className="benifit-head-text pt-1">Texture and taste</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.taste_and_textures}
            </div>
            <div className="benifit-head-text pt-1">Type of fish</div>
            <div className="benifit-sub-text">
              {" "}
              {productDetails?.product_details?.type_of_fish}
            </div>
            <div className="benifit-head-text pt-1">Typical Kerala dishes</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.type_of_fish}
            </div> */}

          {recomendedProduct && recomendedProduct.length > 1 && (
            <div className="col-md-12 cart-container  pt-3">
              <div className="row pt-2">
                <div className="col-md-12 benifit-sub-text">
                  <img src={cart_trolly} alt="Cart" />
                  <span className="cart-text ps-2">Customers also bought:</span>
                </div>
              </div>
              <div className="col-md-12 cart-container  pt-3">
                <div className="row">
                  {recomendedProduct &&
                    recomendedProduct
                      .filter(
                        (product: any) =>
                          productDetails.product_id !== product.product_id
                      )
                      .map((product: any) => (
                        <div
                          className="col-lg-3 col-md-4 col-6 mb-3 content-fishcard"
                          key={product.product_id}
                        >
                          <div
                            className={`card   ${
                              product.out_of_stock
                                ? "stock-card-out-of-stock"
                                : "stock-card card-hghlght-reverse product-card-cat"
                            }`}
                          >
                            <div className="card-body ">
                              <div className="card-img-div-top">
                                <img
                                  className={`card-img-top ${
                                    product.out_of_stock
                                      ? "out-of-stock desaturate"
                                      : ""
                                  }`}
                                  src={
                                    product.product_details?.images?.[0] ??
                                    fish_demo
                                  }
                                  alt="product image"
                                  onClick={() =>
                                    handleImageClick(
                                      product?.product_id,
                                      product?.business_area
                                    )
                                  }
                                />
                              </div>
                              <div className="inner-text-heading-four text-one-line pt-2 ps-2">
                                {product.product_name}
                              </div>
                              <div className="inner-text-content-five-font-12 ps-2">
                                <span className="">
                                  {product.category_name}
                                </span>
                              </div>
                              <div className="inner-text-content-five-font-12 ps-2">
                                <span className="">
                                  {convertWeightToKg(product.weight)}&nbsp;net
                                </span>
                                <span className="font-color-gray">
                                  &nbsp;|&nbsp;
                                </span>
                                <span className="font-color-gray">
                                  {product?.product_details?.number_of_pieces}
                                </span>
                              </div>
                              <div className="inner-text-content-five-font-12 pt-1 pb-1 ps-2">
                                Price:
                                <span className="inner-text-heading-four">
                                  ₹{" "}
                                  {product.vendor_product_inventories?.[0]
                                    ?.price ?? 0}
                                </span>
                              </div>
                              <div className="inner-text-content-five-font-12 pt-1 pb-1">
                                {/* Quantity in Cart:{" "} */}
                                {/* <span className="inner-text-heading-four">
                                {Cartresponse &&
                                  Cartresponse[cartKey]?.products.find(
                                    (item) =>
                                      item.product_id === product.product_id
                                  )?.quantity}
                              </span> */}
                              </div>

                              <div className="mb-0 text-end">
                                <button
                                  className=" add-to-cart-button "
                                  onClick={() =>
                                    handleImageClick(
                                      product?.product_id,
                                      product?.business_area
                                    )
                                  }
                                >
                                  View
                                </button>
                              </div>

                              {/* {product.out_of_stock ? (
                              <div className="out-of-stock-text">
                                <button className="outofstock">
                                  Out of Stock
                                </button>
                              </div>
                            ) : (
                              <>
                                <Cart
                                  product={product}
                                  quantity={
                                    (Cartresponse &&
                                      Cartresponse[cartKey]?.products.find(
                                        (item) =>
                                          item.product_id === product.product_id
                                      )?.quantity) ??
                                    0
                                  }
                                />
                              </>
                            )} */}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductviewDetails;
