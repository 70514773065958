import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  API_GET_MERCHANTS_ID,
  API_GET_MERCHANTS_ID_NEARBY,
  API_GET_MERCHANTS_RECENT,
  API_MERCHANT_VISITOR_COUNT_UPDATE,
} from "../api/merchands";
// import { API_GET_MERCHANTS_ID_NEARBY, API_GET_MERCHANTS_RECENT, API_GET_MERCHANTS_ID,API_MERCHANT_VISITOR_COUNT_UPDATE } from "../api/merchent.service ";
// import { API_GET_MERCHANTS_RECENT, API_GET_MERCHANTS_ID, API_GET_MERCHANTS_ID_NEARBY, API_MERCHANT_VISITOR_COUNT_UPDATE } from "../api/merchent.service";

export interface Merchant {
  id: number;
  name: string;
  address?: string;
}
interface GuestAddress {
  id: number;
  customerId: string;
  addressType: string;
  address1: string | null;
  street: string;
  city: string | null;
  district: string | null;
  state: string | null;
  country: string | null;
  pinCode: string | null;
  mobileNumber: string;
  latitude: string;
  longitude: string;
  landMark: string | null;
  uberH3Index3km: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  vehicleRouteType: string | null;
}
interface AddressState {
  addressList: GuestAddress;
  isLoading: boolean;
  isError: boolean;
}

interface MerchantState {
  merchant: Merchant[];
  merchantById: Merchant;
  recentmerchant: Merchant[];
  selectedMerchant: Merchant;
  // merchantId: number | string;
  foodmerchant: Merchant[];
  selectedfoodmerchant: Merchant;
  grocerymerchant: Merchant[];
  selectedgrocerymerchant: Merchant;
  loading: boolean;
  selectedMerchantId: string;
  selectedMeatMerchantId: string;
  cartMerchantId: string;
  selectedGuestAddress: GuestAddress;
}

const initialState: MerchantState = {
  merchant: [],
  foodmerchant: [],
  grocerymerchant: [],
  merchantById: {} as Merchant,
  recentmerchant: [],
  selectedMerchant: {} as Merchant,
  selectedfoodmerchant: {} as Merchant,
  selectedgrocerymerchant: {} as Merchant,
  selectedMerchantId: '',
  selectedMeatMerchantId: '',
  // merchantId: 0 || "",
  cartMerchantId: "",
  loading: false,
  selectedGuestAddress: {} as GuestAddress,


};


export const getMerchantsNearby = createAsyncThunk(
  "/consumers/merchantsNearby",
  async (
    {
      latitude,
      longitude,
      res,
      business_area,
    }: {
      latitude: number;
      longitude: number;
      res: number;
      business_area: string;
    },
    thunkAPI
  ) => {
    try {
      const resp = await API_GET_MERCHANTS_ID_NEARBY(
        latitude,
        longitude,
        res,
        business_area
      );
      return resp as Merchant[];
    } catch (err) {
      throw err;
    }
  }
);

export const getmerchantrecent = createAsyncThunk(
  "/consumers/merchants/recent",
  async (_, thunkAPI) => {
    try {
      const resp = await API_GET_MERCHANTS_RECENT();
      return resp as Merchant[];
    } catch (err) {
      throw err;
    }
  }
);

export const getMerchantById = createAsyncThunk(
  "/consumers/merchants/id",
  async (
    {
      id,
      latitude,
      longitude,
    }: { id: number; latitude: number; longitude: number },
    thunkAPI
  ) => {
    try {
      const resp = await API_GET_MERCHANTS_ID(id, latitude, longitude);
      return resp as Merchant;
    } catch (err) {
      throw err;
    }
  }
);

export const merchantVisitorCountUpdate = createAsyncThunk(
  "/consumers/merchantVisitors/id",
  async (
    {
      id,
      latitude,
      longitude,
    }: { id: number; latitude: number; longitude: number },
    thunkAPI
  ) => {
    try {
      const resp = await API_MERCHANT_VISITOR_COUNT_UPDATE(
        id,
        latitude,
        longitude
      );
      return resp as number;
    } catch (err) {
      throw err;
    }
  }
);

export const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    setSelectedMerchant: (state, action: PayloadAction<Merchant>) => {
      state.selectedMerchant = action.payload;
      state.merchant = [];
    },

    setSelectedFoodMerchant: (state, action: PayloadAction<Merchant>) => {
      state.selectedfoodmerchant = action.payload;
      state.foodmerchant = [];
    },
    setSelectedGuestAddress: (state, action) => {
      state.selectedGuestAddress = action.payload;
      state.foodmerchant = [];
    },
    setSelectedGroceryMerchant: (state, action: PayloadAction<Merchant>) => {
      state.selectedgrocerymerchant = action.payload;
      state.grocerymerchant = [];
    },
    setSelectedMerchantId: (state, action) => {
      state.selectedMerchantId = action.payload;
    },
    setSelectedMeatMerchantId: (state, action) => {
      state.selectedMeatMerchantId = action.payload;
    },
    setCartMerchantId: (state, action) => {
      state.cartMerchantId = action.payload;
    },
  },
  // export const foodmerchantSlice = createSlice({
  //   name:"foodmerchant",
  //   initialState,
  //   reducers:{
  //     setSelectedMerchantId:(state, action: PayloadAction<string>) =>{
  //       state.selectedMerchantId = action.payload;
  //       state.merchant
  //     }
  //   }
  // })
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantsNearby.fulfilled, (state, action) => {
        state.merchant = action.payload;
        state.loading = false;
      })
      .addCase(getMerchantsNearby.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMerchantsNearby.rejected, (state) => {
        state.merchant = [];
        state.loading = false;
      })

      .addCase(getmerchantrecent.fulfilled, (state, action) => {
        state.recentmerchant = action.payload;
        state.loading = false;
      })
      .addCase(getmerchantrecent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getmerchantrecent.rejected, (state) => {
        state.recentmerchant = [];
        state.loading = false;
      })
      .addCase(getMerchantById.fulfilled, (state, action) => {
        state.merchantById = action.payload;
        state.loading = false;
      })
      .addCase(getMerchantById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMerchantById.rejected, (state) => {
        state.merchantById = {} as Merchant;
        state.selectedMerchant = {} as Merchant;
        state.loading = false;
      });
  },
});

export const { setSelectedMerchant, setSelectedFoodMerchant, setSelectedGroceryMerchant, setSelectedMerchantId, setCartMerchantId, setSelectedGuestAddress, setSelectedMeatMerchantId } =
  merchantSlice.actions;

export default merchantSlice.reducer;
