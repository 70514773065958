import axios from "axios";
import { baseUrl } from "../services/apiConfig";

const sendEventToServer = async (eventData, selectedCurrentLocation) => {
  const { NAME, CUSTOMER_ID, MODULE, PAYLOAD, EVENT_CATEGORY, MERCHANT_ID } =
    eventData;

  try {
    const deviceId = navigator.userAgent;
    const os = navigator.platform;
    const make = "Browser";
    const model = navigator.vendor || "Unknown";

    const getGeolocation = () =>
      new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            ({ coords }) =>
              resolve({
                latitude: coords.latitude,
                longitude: coords.longitude,
              }),
            () => resolve({ latitude: 0, longitude: 0 }),
            { enableHighAccuracy: false, maximumAge: 60000, timeout: 5000 }
          );
        } else {
          console.warn("Geolocation is not supported by this browser.");
          resolve({ latitude: 0, longitude: 0 });
        }
      });

    const { latitude, longitude } = await getGeolocation();

    const data = {
      app_id: "Consumer",
      device_id: deviceId,
      os,
      make,
      model,
      latitude: latitude || selectedCurrentLocation?.coords?.latitude || 0,
      longitude: longitude || selectedCurrentLocation?.coords?.longitude || 0,
      event_name: NAME,
      event_category: EVENT_CATEGORY,
      customer_id: CUSTOMER_ID || "",
      module: MODULE,
      payload: JSON.stringify(PAYLOAD),
      merchant_id: MERCHANT_ID || "",
    };

    await axios.post(
      `${baseUrl}/customer-consumer/customer/service/event-data/insert`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    console.log("Event sent successfully:", data);
  } catch (error) {
    console.error(
      "Error sending event:",
      error.response?.data || error.message
    );
  }
};

export { sendEventToServer };
