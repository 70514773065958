// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unknown-div {
  min-height: 70px;
}
/* @media (max-width: 768px) {
  .unknown-div {
    min-height: 100px;
  }
} */
@media (max-width: 558px) {
  .unknown-div {
    min-height: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;;;;GAIG;AACH;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".unknown-div {\n  min-height: 70px;\n}\n/* @media (max-width: 768px) {\n  .unknown-div {\n    min-height: 100px;\n  }\n} */\n@media (max-width: 558px) {\n  .unknown-div {\n    min-height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
