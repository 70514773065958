import React, { useState } from "react";
import { RestaurantsList } from "../../components";
import Banner from "../../components/banner/Banner";
import ProductCategory from "../../components/Food/ProductCategory/ProductCategory";

const FoodHome = () => {
  const [merchantId, setMerchantId] = useState("");
  const handleMerchantChange = (merchantId: string) => {
    setMerchantId(merchantId);
  };

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page pb-5 relative-div-bg">
        <Banner />
        <ProductCategory onMerchantChange={handleMerchantChange} />
        <RestaurantsList />
      </div>
    </div>
  );
};

export default FoodHome;
