import React, { useState } from "react";
import {
  double_arrow,
  home_icon,
  hotel_icon,
  location_pin,
  previousordericon,
  work_icon,
} from "../../../assets/images";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import AddressModal from "../../addressModal/addressModal";
import "./SavedAddress.css";
import { useDispatch } from "react-redux";

const SavedAddress = () => {
  const [selectedAddress, setSelectedAddress] = useState<any>({}); // Initialize as an empty object
  const [proceedToPayment, setProceedToPayment] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { name, phoneNumber } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState<number | null>(null);

  interface Address {
    id: number;
    address1: string;
    // Add other properties as needed
  }
  const {
    data: addressList = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.ADDRESS_LIST);
      return response.data;
    },
  });

  const handleDeleteAddress = async (addressId: number) => {
    try {
      await axiosInstance.delete(
        `${API_URL.DELIVERY_ADDRESS_DELETE}/${addressId}`
      );
      refetch();
      setIsConfirmationModalOpen(false);
    } catch (error) {
      console.error("Error deleting address:", error);
    }
    setIsConfirmationModalOpen(false);
    setAddressToDelete(null);
  };
  const openConfirmationModal = (addressId: number) => {
    setAddressToDelete(addressId);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setAddressToDelete(null);
  };
  const toggleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
    setIsOpen(false);
  };

  return (
    <div className="col-md-12 height-custom pt-5">
      <div className="sub-div-headings d-flex pt-3">
        <img src={previousordericon} alt="previousordericon" />
        &nbsp;
        <span>Saved Address</span>&nbsp;
        <span className="line-span">
          <div className="line-div-max-length-ecommerce"></div>
        </span>
      </div>
      <div className="row pt-3">
        {!proceedToPayment ? (
          <>
            {addressList &&
              Array.isArray(addressList) &&
              addressList.map((item: any) => (
                <div
                  className="col-md-6 mb-3 text-left"
                  style={{ textAlign: "left" }}
                  key={item?.addressId}
                >
                  <div className="card card-add-height">
                    <div className="card-body" style={{ textAlign: "left" }}>
                      <div className="row">
                        <div className="col-1">
                          <img
                            src={
                              item.addressType === "Home"
                                ? home_icon
                                : item.addressType === "Work"
                                ? work_icon
                                : item.addressType === "Hotel"
                                ? hotel_icon
                                : location_pin
                            }
                            alt="Logo"
                          />
                        </div>
                        <div className="col-9 small-size mt-1 mr-3 address-details">
                          <h5 className="card-title">{item?.addressType}</h5>
                          <p className="card-text address-text">
                            {item?.address1}
                          </p>
                        </div>
                        <div className="text-end">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() =>
                              openConfirmationModal(item?.addressId)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {!proceedToPayment && (
              <div className="col-md-6 mb-3 text-start">
                <div
                  className="card address-card card-add-height  "
                  onClick={toggleAddressModal}
                >
                  <div className="card-body align-content-center text-center">
                    <div className="row">
                      <div className="col-12">
                        <div className="card-title">
                          <img
                            src={location_pin}
                            alt="Location"
                            className="location-custom"
                          />
                          &nbsp;
                          <span>Add new address</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {isConfirmationModalOpen && (
        <div className="address-confirmation-modal">
          <div className="address-modal-content">
            <h5>Are you sure you want to delete this address?</h5>
            <div className="address-modal-actions">
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteAddress(addressToDelete!)}
              >
                Yes
              </button>
              <button
                className="btn btn-secondary"
                onClick={closeConfirmationModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <AddressModal
        isOpen={isAddressModalOpen}
        toggle={toggleAddressModal}
        // refetchAddressList={refetchAddressList}
      />
    </div>
  );
};

export default SavedAddress;
